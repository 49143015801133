import styled from '@emotion/styled'
import type { PropsWithChildren } from 'react'

interface MenuListProps {
  role?: string
  ariaLabel?: string
  id?: string
}

const MenuListStyles = styled.ul({
  position: 'relative',
  zIndex: '0',
})

const MenuList = ({ children, ariaLabel, ...props }: PropsWithChildren<MenuListProps>) => (
  <MenuListStyles aria-label={ariaLabel} {...props}>
    {children}
  </MenuListStyles>
)

export default MenuList
