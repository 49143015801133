/**
 * Aqui devem estar presentes os ícones não essenciais.
 *
 * Os ícones essenciais devem ser incluídos no arquivo core.tsx.
 */

/**
 * A chave do objeto representa a prop "icon" no componente de Icon
 * O valor do objeto representa o nome do arquivo dentro do diretório svg.
 */
export const LAZY_ICONS_MAPPING = {
  user: 'user',
  'circle-plus': 'circlePlus',
  facebook: 'facebook',
  spotify: 'spotify',
  linkedin: 'linkedin',
  instagram: 'instagram',
  youtube: 'youtube',
  star: 'star',
  error: 'error',
  external: 'external',
  whatsapp: 'whatsapp',
  check: 'check',
  'ghost-whatsapp': 'ghost-whatsapp',
  'ghost-facebook': 'ghost-facebook',
  'ghost-linkedin': 'ghost-linkedin',
  'ghost-twitter': 'ghost-twitter',
  'ghost-email': 'ghost-email',
  'ghost-link': 'ghost-link',
  'ghost-star': 'ghost-star',
  'arrow-right': 'arrow-right',
  checked: 'checked',
  global: 'global',
}
