import { useCallback } from 'react'

import { Image } from '@/legacy/components/Image'
import { useVerticalProduct } from '@/legacy/core/analytics/hooks'
import { useSelectSeloEvent } from '@/legacy/core/analytics/hooks/useSelectSeloEvent'

import ImgFriendOfJustice from '/public/img-friend-justice.png'
import ImgGlobalSign from '/public/img-global-sign.png'

const linkFriendOfJustice =
  'https://www.serasa.com.br/imprensa/serasa-se-torna-parceira-institucional-do-programa-empresa-amiga-da-justica/'

const FooterStamp = () => {
  const verticalProduct = useVerticalProduct()
  const selectSeloEvent = useSelectSeloEvent()

  const handleSelectSelo = useCallback(
    () =>
      selectSeloEvent({
        label: `amiga-da-justica-parceiro-institucional`,
        itemId: `${verticalProduct}_web_selo_rodape_04`,
      }),
    [selectSeloEvent],
  )

  return (
    <div className="footer-stamp">
      <div>
        <a href={linkFriendOfJustice} onClick={handleSelectSelo} target="_blank" rel="noreferrer">
          <Image
            source={ImgFriendOfJustice}
            width="60"
            height="78"
            layout="fixed"
            alt="Selo amiga da justiça"
          />
        </a>
      </div>

      <div className="global-sign">
        <Image
          source={ImgGlobalSign}
          width="110"
          height="45"
          layout="fixed"
          alt="GlobalSign"
          aria-hidden
        />
      </div>
    </div>
  )
}

export default FooterStamp
