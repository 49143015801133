import { CookieStorage } from '@/legacy/core/utils/cookies'

import type { SampleRate } from './service/types'

export const APP_VERSION = process.env.APP_VERSION
export const DD_ENV = process.env.DD_ENV
export const DD_APPLICATION_ID = process.env.DD_APPLICATION_ID as string
export const DD_CLIENT_TOKEN = process.env.DD_CLIENT_TOKEN as string

export const DD_SITE = 'datadoghq.com'
export const PROJECT_NAME = 'ecs-web-public-pages-front'
export const ALLOWED_TRACING_ORIGINS = [
  'https://apigw-commons-hml.ecsbr.net',
  'https://api.serasa.dev',
  'https://apigw-commons-prd.ecsbr.net',
  'https://api.serasa.com.br',
]

export const IS_DD_ENABLED = process.env.DD_ENABLED
export const DD_IS_SESSION_REPLAY_ENABLED = process.env.DD_IS_SESSION_REPLAY_ENABLED

export const HOME_PAGE_ROLLOUT = {
  path: process.env.HOME_PAGE_PATH,
  isSessionReplayEnabled: process.env.DD_IS_SESSION_REPLAY_ENABLED_HOME,
}

const RUM_SAMPLE_RATE = process.env.DD_RUM_SAMPLE_RATE
const SESSION_REPLAY_SAMPLE_RATE = process.env.DD_SESSION_REPLAY_SAMPLE_RATE
const LOGS_SESSION_SAMPLE_RATE = process.env.DD_LOGS_SESSION_SAMPLE_RATE

export const SAMPLE_RATES = {
  rum: Number(RUM_SAMPLE_RATE),
  sessionReplay: Number(SESSION_REPLAY_SAMPLE_RATE),
  logs: Number(LOGS_SESSION_SAMPLE_RATE),
}

export const DEFAULT_SAMPLE_RATES: SampleRate = { rum: 0, sessionReplay: 0, logs: 0 }

export const USER_ORIGIN_COOKIE = CookieStorage.get('S_MEDIA_ORIGIN')
export const MEDIA_SOURCE = 'md_source'
export const MEDIA_MEDIUM = 'md_medium'

export const serviceName = `${DD_ENV}---${PROJECT_NAME}`

export const COMMON_CONFIG = {
  clientToken: DD_CLIENT_TOKEN,
  site: DD_SITE,
  service: serviceName,
  env: DD_ENV,
  version: APP_VERSION,
}
