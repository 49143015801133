import { createContext, useContext } from 'react'

import { type Device } from '@/legacy/core/utils/getDevice'
import { Logger } from '@/services/logs'

const DeviceContextNotProvided = 'DeviceContextNotProvided'

export const DEVICE_PROVIDER_ERROR = {
  code: 'device-provider',
  message: 'useDevice must be used within a DeviceProviders',
}

const DeviceContext = createContext<Device | typeof DeviceContextNotProvided>(
  DeviceContextNotProvided,
)

export function DeviceProvider({
  device,
  children,
}: {
  device: Device
  children: React.ReactNode
}) {
  return <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
}

export function useDevice() {
  const device = useContext(DeviceContext)

  if (device === DeviceContextNotProvided) {
    Logger.error(DEVICE_PROVIDER_ERROR.code, { message: DEVICE_PROVIDER_ERROR.message })
    return
  }

  return device
}
