/**
 * Por questões de performance alguns ícones são incluídos no bundle inicial e outros são carregados sob demanda.
 *
 * Para adicionar um novo icon deve ser criado um arquivo com o svgPath do ícone dentro do diretório "svg"
 * e ele deve ser linkado dentro de icons/core.tsx ou icons/lazy.tsx.
 *
 * Mais detalhes no link:
 * https://serasaexperian.atlassian.net/wiki/spaces/CAD/pages/3376840778/Carregamento+de+cones+SVG+no+Ssw-front
 */
import styled from '@emotion/styled'
import cx from 'classnames'
import { forwardRef, type PropsWithChildren, type SVGProps } from 'react'

import type { IconProps, IconRef } from './types'
import { useSvgPath } from './useSvgPath'

export type { IconProps, Icon as TIcon } from './types'

const IconStyles = styled.span<Partial<Pick<IconProps, 'space'>>>((props) => ({
  display: 'inline-flex',

  ...(props.space === 'right' && {
    marginRight: '0.5rem',
  }),

  ...(props.space === 'left' && {
    marginLeft: '0.5rem',
  }),

  '& svg': {
    width: '1em',
    height: '1em',
    fill: 'currentColor',
  },
  '&.angle-up': {
    transform: 'rotate(90deg)',
  },
  '&.angle-down': {
    transform: 'rotate(-90deg)',
  },
  '&.angle-right': {
    transform: 'rotate(180deg)',
  },
}))

const SvgWrapper = ({ children, ...props }: PropsWithChildren<SVGProps<SVGSVGElement>>) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 1024 1024"
    role="graphics-document img"
    {...props}
  >
    {children}
  </svg>
)

export const Icon = forwardRef<IconRef, IconProps>(
  (
    {
      tag = 'i',
      icon,
      href,
      className,
      color,
      space,
      width = '28',
      height = '28',
      svgProps = {},
      ...anchorProps
    }: IconProps,
    ref,
  ) => {
    const Component = href ? 'a' : tag
    const props = { ref, href, ...anchorProps }
    const PathComponent = useSvgPath(icon)

    return (
      <IconStyles
        as={Component}
        space={space}
        className={cx(icon, className, {
          [`et-${color}`]: color,
        })}
        {...props}
      >
        <SvgWrapper width={width} height={height} {...svgProps}>
          <PathComponent />
        </SvgWrapper>
      </IconStyles>
    )
  },
)

Icon.displayName = 'Icon'

Icon.defaultProps = {
  tag: 'i',
}

export default Icon
