import styled from '@emotion/styled'

import { forwardRef } from 'react'

import { fadeIn, fadeOut } from '@/ui/shared/styles'

interface SidebarMaskProps {
  open: boolean
  hidden: boolean
  sideBarState: 'close' | 'open' | 'animating'
  onClickMask: () => void
}

const SidebarMaskStyles = styled.div<Pick<SidebarMaskProps, 'open' | 'hidden' | 'sideBarState'>>(
  (props) => ({
    backgroundColor: 'rgba(0,0,0,0.5)',
    animationDuration: '233ms',
    animationTimingFunction: 'cubic-bezier(0,0,.21,1)',
    animationFillMode: 'forwards',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    backgroundImage: 'none',
    animationName: `${fadeIn}`,
    zIndex: '1046',
    cursor: 'pointer',

    ...(props.sideBarState === 'open' && {
      animation: 'none',
    }),

    ...(props.hidden && {
      display: 'none',
    }),

    ...(!props.open && {
      animationName: `${fadeOut}`,
    }),
  }),
)

const SidebarMask = forwardRef<HTMLDivElement, SidebarMaskProps>(
  ({ open, sideBarState, hidden, onClickMask }, ref) => (
    <SidebarMaskStyles
      ref={ref}
      open={open}
      sideBarState={sideBarState}
      hidden={hidden}
      aria-label="Fechar"
      aria-hidden={true}
      onClick={onClickMask}
    />
  ),
)

SidebarMask.displayName = 'SidebarMask'

export default SidebarMask
