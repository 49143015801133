import cx from 'classnames'
import { forwardRef } from 'react'

import MaskedInput, { type MaskedInputProps } from '../MaskedInput'

import { FieldStyles as TextFieldStyles } from '../shared/styles'
import type { FieldProps } from '../shared/types'

export type TextFieldProps = FieldProps & Partial<MaskedInputProps>

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      autoSelect = false,
      colorModifier = 'gray',
      customFocus = true,
      centerPlaceholder,
      className,
      italic,
      children,
      mask,
      ...inputProps
    },
    ref,
  ) => (
    <TextFieldStyles
      className={cx(
        {
          [`--${colorModifier}`]: !!colorModifier,
          ['--italic']: italic,
          ['--custom-focus']: customFocus,
          ['--centerPlaceholder']: centerPlaceholder,
        },
        className,
      )}
    >
      {!mask ? (
        <input onFocus={(event) => autoSelect && event.target.select()} ref={ref} {...inputProps} />
      ) : (
        <MaskedInput
          mask={mask}
          onFocus={(event) => autoSelect && event.target.select()}
          inputRef={ref}
          {...inputProps}
        />
      )}
      {children}
    </TextFieldStyles>
  ),
)

TextField.displayName = 'TextField'

export default TextField
