import { CookieStorage } from '@/legacy/core/utils/cookies'
import { COOKIE_KEY_AU } from '@/legacy/server/services/session/constants'
import { Logger } from '@/services/logs'

import { fetchInitialData } from '../services/vcpe'
import type { ConsultData } from '../types'

export const fetchConsultData = async (): Promise<undefined | ConsultData> => {
  const token = CookieStorage.get(COOKIE_KEY_AU)
  if (!token) return undefined

  try {
    return await fetchInitialData(token)
  } catch (error) {
    Logger.error('error-fetch-consult-data', { ...error })
    return undefined
  }
}
