import { dateToEnUsFormat } from './dateToEnUsFormat'
import { isLeapYear } from './isLeapYear'

export const validateDate = (value: string, allowFuture: boolean) => {
  const day = parseInt(value.slice(0, 2))
  const month = parseInt(value.slice(2, 4))
  const year = parseInt(value.slice(4, 8))
  const currentDate = new Date()

  const isDayValid = day >= 1 && day <= 31
  const isMonthValid = month >= 1 && month <= 12
  const isYearValid = year >= 1850 && year <= 2300

  if (!isDayValid || !isMonthValid || !isYearValid) {
    return false
  }

  const has30Days = [4, 6, 9, 11].includes(month)
  const isFebruary = month === 2
  const isLeapYearValid = isLeapYear(year)

  if ((has30Days && day > 30) || (isFebruary && day > (isLeapYearValid ? 29 : 28))) {
    return false
  }

  const inputDate = new Date(dateToEnUsFormat(value))

  if (!allowFuture && inputDate >= currentDate) {
    return false
  }

  return true
}
