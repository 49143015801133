import cx from 'classnames'

import { forwardRef, type ChangeEvent, type InputHTMLAttributes } from 'react'

import { currencyToNumber, formatCurrency } from '@/legacy/core/utils/currency'

import { FieldStyles as CurrencyFieldStyles } from '../shared/styles'
import type { FieldProps } from '../shared/types'

type CurrencyFieldProps = FieldProps & InputHTMLAttributes<HTMLInputElement>

const transform = {
  output: (e: ChangeEvent<HTMLInputElement>) => currencyToNumber(e?.target?.value || ''),
  input: (e: string) => formatCurrency(e),
}

const CurrencyField = forwardRef<HTMLInputElement, CurrencyFieldProps>(
  (
    {
      autoSelect = false,
      colorModifier = 'gray',
      customFocus = true,
      centerPlaceholder,
      className,
      italic,
      children,
      ...props
    },
    ref,
  ) => (
    <CurrencyFieldStyles
      className={cx(
        {
          [`--${colorModifier}`]: !!colorModifier,
          ['--italic']: italic,
          ['--custom-focus']: customFocus,
          ['--centerPlaceholder']: centerPlaceholder,
        },
        className,
      )}
    >
      <input
        inputMode="numeric"
        defaultValue={transform.input('0')}
        onFocus={(event) => {
          autoSelect && event.target.select()
        }}
        onChange={(event) => {
          const value = transform.output(event)
          event.target.value = transform.input(String(value))
        }}
        ref={ref}
        {...props}
      />
      {children}
    </CurrencyFieldStyles>
  ),
)

CurrencyField.displayName = 'CurrencyField'

export default CurrencyField
