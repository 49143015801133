import Head from 'next/head'
import { useRouter } from 'next/router'

import { Gtm, Schemas } from '@/legacy/components/Scripts'
import { sanitizeUrl } from '@/legacy/core/utils/dom/security'
import type {
  AnalyticsData,
  BuilderPageModelMetatags,
  ContentReview,
  MetatagsData,
} from '@/legacy/models'

import { WebVitals } from '../WebVitals'

import { generateSchemas } from './utils'

interface MetatagsProps extends BuilderPageModelMetatags {
  contentReview?: ContentReview
}

const BASE_URL = process.env.NEXT_PUBLIC_HOST

const Metatags = ({ data, contentReview = {} }: MetatagsProps) => {
  const router = useRouter()
  const analytics = (data?.analytics || {}) as AnalyticsData
  const { metaTags, robots, ...tools } = (data || {}) as MetatagsData
  const { title, description, ogImage } = (metaTags || {}) as MetatagsData['metaTags']

  const url = data?.url || router.asPath

  const isHome = url === '/'

  const parsedUrl = isHome ? url : `/${url.replace(/^\/|\/$/g, '')}/`

  const robotsContent =
    robots &&
    !url.includes('builder.preview') &&
    !url.includes('builder.frameEditing') &&
    !url.includes('__builder_editing__')
      ? 'index, follow'
      : 'noindex'
  const canonical = `${BASE_URL}${parsedUrl}`

  const schemas = generateSchemas({
    pageTitle: title,
    contentReview,
    defaultSchemas: tools.schemas,
  })

  const hasAnalyticsTitle = !!(analytics.title && analytics.title.length)

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="robots" content={robotsContent} />
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta name="twitter:description" content={description} />
        <link key="canonical" rel="canonical" href={sanitizeUrl(canonical)} />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {ogImage && (
          <>
            <meta property="og:image" content={ogImage} />
            <meta name="twitter:image" content={ogImage} />
          </>
        )}
        {isHome && (
          <>
            <meta
              name="google-site-verification"
              content="8QPkNQ1gX1n5kPp0C_r_29uGnIrH-WqEYnVX2jXsZ14"
            />
            <meta name="HandheldFriendly" content="True" />
            <meta httpEquiv="cleartype" content="on" />
            <meta name="facebook-domain-verification" content="m0twakdnmqo8d6gffi4w2dv2pchuxp" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:url" content="https://www.serasa.com.br" />
            <meta property="og:type" content="article" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:site_name" content="Serasa" />
            <link
              rel="prefetch"
              href="/entrar?product=portal&redirectUrl=/area-cliente/"
              as="document"
            />
          </>
        )}
      </Head>
      <Gtm
        url={parsedUrl}
        {...analytics}
        title={hasAnalyticsTitle ? (analytics.title as string) : title}
      />
      <Schemas schemas={schemas} />
      <WebVitals />
    </>
  )
}

export default Metatags
