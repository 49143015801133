import styled from '@emotion/styled'

const ContainerStyled = styled.div`
  display: flex;
  height: 4rem;
  max-height: 4rem;
  padding: 0.5rem;

  background-color: rgb(var(--theme-white));
  .close-button {
    min-width: 2rem;
    min-height: 3rem;
    border: 0;
    padding: 0;
    font-size: 0.75rem;
  }

  .icon {
    margin: 0 0.5rem;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-right: auto;
  }
  .download-button {
    min-width: 4rem;
    min-height: 3rem;
    padding: 0 0.5rem;
  }
`

export { ContainerStyled }
