import cx from 'classnames'

import { forwardRef, type InputHTMLAttributes } from 'react'

import { FieldStyles as DateFieldStyles } from '../shared/styles'
import type { FieldProps } from '../shared/types'

import { useDateField } from './useDateField'

type DateFieldProps = FieldProps &
  InputHTMLAttributes<HTMLInputElement> & { onChange?: () => void; allowFuture?: boolean }

const DateField = forwardRef<HTMLInputElement, DateFieldProps>(
  (
    {
      autoSelect = false,
      colorModifier = 'gray',
      customFocus = true,
      allowFuture = true,
      centerPlaceholder,
      className,
      italic,
      children,
      onChange,
      ...props
    },
    forwardedRef,
  ) => {
    const handleDateChange = useDateField(forwardedRef, allowFuture)

    return (
      <DateFieldStyles
        className={cx(
          {
            [`--${colorModifier}`]: !!colorModifier,
            ['--italic']: italic,
            ['--custom-focus']: customFocus,
            ['--centerPlaceholder']: centerPlaceholder,
          },
          className,
        )}
      >
        <input
          inputMode="numeric"
          onFocus={(event) => autoSelect && event.target.select()}
          onChange={onChange || handleDateChange}
          ref={forwardedRef}
          {...props}
        />
        {children}
      </DateFieldStyles>
    )
  },
)

DateField.displayName = 'DateField'

export default DateField
