import { useRouter } from 'next/router'
import { createContext, useContext } from 'react'

import { RumReport } from '@/legacy/core/monitoring'

import { Logger } from '@/services/logs'

import { getEventKey } from './get-event-key'
import { getEventProduct } from './get-event-product'

import type { EventContextProps, EventProviderProps } from './types'

const EventContext = createContext<EventContextProps>({
  sendEvent: () => {
    Logger.error('martech:event-context-not-provided', {})
    return { key: 'martech:click', block: '', page: '', product: 'none' }
  },
})

export const EventProvider = ({ block, children }: EventProviderProps) => {
  const router = useRouter()

  const sendEvent = (href: string) => {
    const urlPath = router.asPath
    const key = getEventKey(href)
    const context = { page: urlPath, product: getEventProduct(urlPath), key, block }
    RumReport.action(key, context)
    return context
  }

  return <EventContext.Provider value={{ sendEvent }}>{children}</EventContext.Provider>
}

export const useSendEvent = () => useContext(EventContext)
