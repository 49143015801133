import { useRouter } from 'next/router'

import { useFirstMountState } from 'react-use'

import { useMedia } from '@/legacy/hooks/useMedia'

export const useIsMobile = (maxWidth = '959px') => {
  const { query } = useRouter()
  const firstMount = useFirstMountState()
  const device = query?.mwdv
  const mediaIsMobile = useMedia(`(max-width: ${maxWidth})`)

  return firstMount ? !!device && device === 'mobile' : mediaIsMobile
}
