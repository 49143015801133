import type { ChangeEvent, ForwardedRef } from 'react'

import { formatDate } from '@/legacy/core/utils/date/formatDate'
import { validateDate } from '@/legacy/core/utils/date/validateDate'

export const useDateField = (inputRef: ForwardedRef<HTMLInputElement>, allowFuture: boolean) => {
  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target
    const inputValueWithNumbers = input.value.replace(/\D/g, '')
    const formattedDateValue = formatDate(inputValueWithNumbers)
    const isValidDate = validateDate(inputValueWithNumbers, allowFuture)

    if (!isValidDate) input.setCustomValidity('Digite uma data de nascimento válida.')
    else input.setCustomValidity('')

    if (inputRef != null && typeof inputRef !== 'function' && inputRef.current) {
      inputRef.current.value = formattedDateValue
    }
  }

  return handleDateChange
}
