export const BUILDER_SERASA_PUBLIC_KEY = process.env.NEXT_PUBLIC_BUILDER_SERASA_PUBLIC_KEY as string
export const BUILDER_PV_PUBLIC_KEY = process.env.NEXT_PUBLIC_BUILDER_PV_PUBLIC_KEY as string
export const BUILDER_PAGE_MODEL = process.env.NEXT_PUBLIC_BUILDER_PAGE_MODEL as string
export const BUILDER_BLOG_MODEL = process.env.NEXT_PUBLIC_BUILDER_BLOG_MODEL as string
export const BUILDER_FOOTER_MODEL = process.env.NEXT_PUBLIC_BUILDER_FOOTER_MODEL as string
export const BUILDER_CATEGORY_OTHERS_ARTICLES = process.env
  .NEXT_PUBLIC_BUILDER_CATEGORY_OTHERS_ARTICLES as string
export const BUILDER_ADS_MODEL = process.env.NEXT_PUBLIC_BUILDER_ADS_MODEL as string

export const BUILDER_ADS_QUERY = {
  top: { 'data.type': 'top' },
  middle: { 'data.type': 'middle' },
  bottom: { 'data.type': 'bottom' },
} as const
export const BUILDER_CONTENT_API_HOST = process.env.NEXT_PUBLIC_BUILDER_CONTENT_API_HOST as string
export const BUILDER_CONTENT_API_URL = `${BUILDER_CONTENT_API_HOST}/api/v3/content`
export const BUILDER_ANNOUNCEMENT_BAR_MODEL = process.env
  .NEXT_PUBLIC_BUILDER_ANNOUNCEMENT_BAR_MODEL as string
export const BUILDER_CONTENT_REVIEW_MODEL = process.env
  .NEXT_PUBLIC_BUILDER_CONTENT_REVIEW_MODEL as string

export const BUILDER_COURSE_MODEL = process.env.NEXT_PUBLIC_BUILDER_COURSE_MODEL as string
export const BUILDER_COURSE_LESSON_MODEL = process.env
  .NEXT_PUBLIC_BUILDER_COURSE_LESSON_MODEL as string

export const BUILDER_PARTNERS_MODEL = process.env.NEXT_PUBLIC_BUILDER_PARTNERS_MODEL as string
