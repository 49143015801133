const BASE_ITEM_ID = 'serasa-web_web_link_topo'
export type Event = {
  itemId: string
  label: string
}
export const EVENTS_ITEMS = {
  PME: {
    itemId: `${BASE_ITEM_ID}_01`,
    label: 'para-pme',
  },
  empresas: {
    itemId: `${BASE_ITEM_ID}_02`,
    label: 'para-empresas',
  },
  global: {
    itemId: `${BASE_ITEM_ID}_03`,
    label: 'global',
  },
}
