const SERASA_APP_ICON =
  'https://cdn.builder.io/api/v1/image/assets%2Fb212bb18f00a40869a6cd42f77cbeefc%2Fbb42782bec4c4c35b08e36d0caefe306?format=SVG'

const DOWNLOAD_APP_URL = 'http://serasa.news/app_serasa'

const DEEP_LINK_URL =
  'https://snk2.adj.st/area-cliente?utm_source=site&utm_medium=home-serasa&utm_campaign=home_barra_instale_app&utm_content=barra&utm_term=instale_app&adj_t=1d1mzsgv&adj_deep_link=https%3A%2F%2Fserasa.com.br%2Farea-cliente%3Futm_source%3Dsite%26utm_medium%3Dhome-serasa%26utm_campaign%3Dhome_barra_instale_app%26utm_content%3Dbarra%26utm_term%3Dinstale_app'

const APP_INSTALL_BANNER_COOKIE_KEY = 'S_IS_APP_INSTALL_BANNER_DISABLED'

export { APP_INSTALL_BANNER_COOKIE_KEY, DEEP_LINK_URL, DOWNLOAD_APP_URL, SERASA_APP_ICON }
