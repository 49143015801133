import { DD_ENV } from '@/legacy/core/monitoring/constants'

import { hasWindow } from '@/legacy/core/utils/dom/utils'

import { DatadogLogService, PinoLogService, type LogLevel, type LogService } from './log-services'

const isProductionEnvironment = DD_ENV === 'prd'
const isLocalEnvironment = DD_ENV === 'local'

export class Logger {
  constructor(
    private readonly logService: LogService,
    readonly serviceName: string,
  ) {}

  error(code: string, context: object) {
    return this.event(code, context, 'error')
  }

  debug(code: string, context: object) {
    return this.event(code, context, 'debug')
  }

  info(code: string, context: object) {
    return this.event(code, context, 'info')
  }

  warn(code: string, context: object) {
    return this.event(code, context, 'warn')
  }

  private event(code: string, context: object, level = 'info' as LogLevel) {
    try {
      this.logService.send(code, context, level)
      return { sent: true }
    } catch (error) {
      if (!isProductionEnvironment || !hasWindow()) {
        console.error({ code: 'acquisition:logger:event', error })
      }
      return { sent: false }
    }
  }
}

function makeLogger() {
  const pinoLogService = new PinoLogService()
  const dataDogLogService = new DatadogLogService()
  const shouldUseDataDogLogService = hasWindow() && !isLocalEnvironment
  const { logService, serviceName } = shouldUseDataDogLogService
    ? { logService: dataDogLogService, serviceName: 'datadog' }
    : { logService: pinoLogService, serviceName: 'pino' }
  return new Logger(logService, serviceName)
}

export default makeLogger()
