import styled from '@emotion/styled'

import { CONFIG } from '@/ui/shared/styles'

export const FieldStyles = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid;
  transition: 0.3s ease;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  min-height: 2.5rem;

  ${CONFIG.media.lg} {
    min-height: 3.25rem;
  }

  &.--gray {
    border-color: rgba(var(--theme-surface-silver));
    color: rgba(var(--theme-dark-medium));

    input,
    input::placeholder {
      color: rgba(var(--theme-dark-medium));
    }
  }

  &.--light {
    border-color: rgba(var(--theme-snow));
    input {
      color: rgba(var(--theme-snow));
    }
  }

  &.--ghost {
    border: none;
    background-color: transparent;

    input {
      color: rgba(var(--theme-dark-medium));
    }
  }

  &.--custom-focus {
    &:focus-within {
      box-shadow: ${CONFIG.theme.shadows.primary};
      border-color: rgba(var(--colors-primary));
    }

    &:hover {
      border-color: rgba(var(--colors-primary));
    }
  }

  &.--centerPlaceholder {
    input::placeholder {
      text-align: center;
    }
  }

  &.--italic {
    input {
      font-style: italic;
    }
  }

  input {
    width: 100%;
    border: none;
    margin: 0.25rem;
    font-size: 1rem;
  }
`
