import dynamic from 'next/dynamic'
import { useMemo } from 'react'

import type { InputMaskProps } from './InputMask'

const MaskedInput = (props: InputMaskProps) => {
  const InputMaskNoSSR = useMemo(
    () =>
      dynamic(() => import('./InputMask'), {
        ssr: false,
      }),
    [],
  )

  return <InputMaskNoSSR {...props} />
}

export default MaskedInput

export type { InputMaskProps as MaskedInputProps }
