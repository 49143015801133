import {
  Typography as PolycupsTypography,
  type TypographyModifier,
  type TypographyProps as PolycupsTypographyProps,
} from '@polycups/react'

import type { AnchorHTMLAttributes } from 'react'

import { clearBuilderProps } from '@/legacy/core/utils/dom/clearBuilderProps'

export type TypographyColorModifier = 'light' | 'dark'

interface TypographyHOCProps extends Omit<TypographyProps, 'tag' | 'modifier'> {
  tag: string
  modifier: TypographyModifier
}

export interface TypographyProps
  extends PolycupsTypographyProps,
    Partial<Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'children' | 'color'>> {
  light?: 'high' | 'solid'
  dark?: 'low' | 'medium' | 'high'
}

const typographyHOC = (options: TypographyHOCProps) => {
  const TypographyFn = ({
    tag,
    html,
    light,
    dark = 'high',
    color,
    ...otherProps
  }: TypographyProps) => {
    const props = {
      ...options,
      tag: tag || options.tag || 'span',
      html: html || '',
      color: color || (light ? (`light-${light}` as const) : dark && (`dark-${dark}` as const)),
      ...otherProps,
    }

    clearBuilderProps(props)

    return <PolycupsTypography {...props} />
  }

  return TypographyFn
}

export const Display = typographyHOC({
  modifier: 'display',
  tag: 'p',
})

export const HeadingL = typographyHOC({
  modifier: 'heading-l',
  tag: 'p',
})

export const HeadingM = typographyHOC({
  modifier: 'heading-m',
  tag: 'p',
})

export const HeadingS = typographyHOC({
  modifier: 'heading-s',
  tag: 'p',
})

export const HeadingXS = typographyHOC({
  modifier: 'heading-xs',
  tag: 'p',
})

export const SubHeading = typographyHOC({
  modifier: 'subheading',
  tag: 'p',
})

export const BodyM = typographyHOC({
  modifier: 'body-m',
  tag: 'p',
})

export const BodyP = typographyHOC({
  modifier: 'body-p',
  tag: 'p',
})

export const Caption = typographyHOC({
  modifier: 'caption',
  tag: 'span',
})

export const Typography = typographyHOC({ tag: 'span', modifier: 'body-m' })
