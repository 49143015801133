/**
 * Aqui devem estar presentes os ícones essenciais,
 * que são utilizados por todas as páginas ou que são utilizados em componentes do core.
 *
 * Os ícones não essenciais devem ser incluídos no arquivo lazy.tsx.
 */

import angleLeft from '../svg/angleLeft'
import bullet from '../svg/bullet'
import clock from '../svg/clock'
import close from '../svg/close'
import doubleQuotes from '../svg/double-quotes'
import global from '../svg/global'
import magnifier from '../svg/magnifier'
import out from '../svg/out'
import serasa from '../svg/serasa'
import videoPlayer from '../svg/videoPlayer'
import whatsapp from '../svg/whatsapp'

export const CORE_ICONS_MAPPING = {
  serasa: serasa,
  close: close,
  bullet: bullet,
  magnifier: magnifier,
  'video-player': videoPlayer,
  'angle-down': angleLeft,
  'angle-left': angleLeft,
  'angle-right': angleLeft,
  'angle-up': angleLeft,
  'double-quotes': doubleQuotes,
  clock: clock,
  global: global,
  whatsapp: whatsapp,
  out: out,
}
