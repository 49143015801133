import axios from 'axios'

import { SERASA_API_BASE_URL } from '@/legacy/config/api'

export const ApiserasaPremium = axios.create({
  baseURL: `${SERASA_API_BASE_URL}premium`,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
  },
})
