import styled from '@emotion/styled'
import type { FormEvent, MutableRefObject } from 'react'

import { TextFieldWithButton } from '@/legacy/components/Input'

import { useBlogSearch } from '@/legacy/components/SearchBar/useBlogSearch'
import { useForceFocusOnElement } from '@/legacy/hooks/useForceFocusOnElement'

import type { TextFieldWithButtonProps } from '../Input/TextFieldWithButton/TextFieldWithButton'

const SearchBarStyles = styled.div`
  display: flex;
`

const SearchBarForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    font-size: 1rem;
  }
`

export interface SearchBarProps extends TextFieldWithButtonProps {
  buttonLabel?: string
  onClose: () => void
  componentRef?: MutableRefObject<HTMLDivElement | null>
  forceInputFocus?: boolean
}

const SearchBar = ({
  buttonLabel,
  onClose,
  className,
  componentRef,
  forceInputFocus = true,
  ...inputProps
}: SearchBarProps) => {
  const { searchInputRef, handleOnSubmit } = useBlogSearch()

  useForceFocusOnElement(searchInputRef, forceInputFocus)

  const handleSubmit = (event: FormEvent) => {
    handleOnSubmit(event)
    onClose()
  }

  return (
    <SearchBarStyles ref={componentRef} className={className}>
      <SearchBarForm onSubmit={handleSubmit}>
        <TextFieldWithButton type="text" inputRef={searchInputRef} {...inputProps}>
          {buttonLabel}
        </TextFieldWithButton>
      </SearchBarForm>
    </SearchBarStyles>
  )
}

export default SearchBar
