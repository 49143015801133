import styled from '@emotion/styled'

export const FooterStyles = styled.footer({
  position: 'relative',
  borderTop: '6px solid rgba(var(--colors-primary))',
  padding: '1.5rem 3rem 6rem 3rem',
  gap: '1rem',
  color: 'rgba(var(--colors-surface-darkgrey))',
  marginTop: '2rem',

  '& .footer-container': {
    margin: '0 auto',
    maxWidth: '120rem',
  },
  '& .footer-columns': {
    display: 'flex',
    flex: 1,
  },
  '& .footer-column': {
    flex: 1,
  },
  '& .footer-link': {
    margin: '0 0 0.25em',
    '& a': {
      textDecoration: 'none',
      display: 'inline-block',
      padding: '0.25rem 0',
      transition: 'color .3s',
      '&:hover': {
        color: 'rgba(var(--colors-primary))',
      },
    },
  },
  '& .footer-title': {
    margin: '1rem 0',
  },
  '& .footer-extra-links': {
    display: 'flex',
    justifyContent: 'space-around',
    borderTop: '1px solid rgba(var(--colors-surface-silver))',
    borderBottom: '1px solid rgba(var(--colors-surface-silver))',
    margin: '2.5rem 0',
    padding: '2.5rem 0',

    '& a': {
      color: 'rgba(var(--colors-primary))',
      borderBottom: '1px solid currentColor',
      textDecoration: 'none',
      fontSize: '.875rem',
    },
  },
  '& .footer-social-networks': {
    display: 'flex',
    gap: '3rem',
    fontSize: '1.75rem',
    svg: {
      fill: 'rgba(var(--colors-text-dark-medium))',
      transition: 'fill .3s',
    },
    '& a:hover svg': {
      fill: 'rgba(var(--colors-primary))',
    },
  },
  '& .footer-apps': {
    display: 'flex',
    gap: '1rem',
  },
  '& .footer-etc': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '3rem 0 1rem 0',
    gap: '1.5rem',
  },
  '& .footer-stamp': {
    display: 'flex',
    alignItems: ' center',
    gap: '2rem',
  },
  '& .address-content': {
    letterSpacing: '0.3px',
    fontSize: '12px',
    margin: '0 0 0.5em 0',
    color: 'rgba(var(--theme-text-dark-high))',
  },

  '@media screen and (max-width: 960px)': {
    '& .footer-etc': {
      flexDirection: 'column',
    },
    '& .footer-social-networks': {
      gap: '2rem',
    },
  },

  '@media screen and (max-width: 768px)': {
    padding: '1.5rem',

    '& .footer-etc': {
      gap: '1rem',
      marginTop: '1rem',
    },
    '& .footer-columns, & .footer-extra-links, & .footer-etc': {
      flexDirection: 'column',
    },
    '& .footer-extra-links a': {
      margin: '.5rem 0',
      display: 'inline-block',
    },
    '& .global-sign': {
      marginTop: '1.5rem',
      display: 'inline-block',
    },
  },
})
