import styled from '@emotion/styled'

import { FormMessage } from '@/legacy/components/FormMessage'

import MaskedInput, { type MaskedInputProps } from '../MaskedInput'

import { useValidation } from './useValidation'
import { maskMapper } from './utils'

interface InputWithMaskValidationProps extends Partial<MaskedInputProps> {
  label?: string
  hasLightText?: boolean
  errorMessage?: string
}

const InputWithMaskValidationStyles = styled.div<{ light?: boolean }>((props) => ({
  width: '100%',
  display: 'inline-block',

  '& input': {
    width: '100%',
    border: '1px solid',
    padding: '1rem',
    borderRadius: 'var(--radii-default)',
    backgroundColor: ' transparent',
    fontStyle: 'italic',

    ...(props.light && {
      borderColor: 'rgba(var(--theme-snow))',
      color: 'rgba(var(--theme-snow))',
    }),
  },

  ...(props.light && {
    '& input:placeholder': {
      color: 'rgba(var(--theme-snow))',
    },
  }),
}))

const InputWithMaskValidation = ({
  name,
  label,
  hasLightText,
  errorMessage,
  mask = '',
  ...props
}: InputWithMaskValidationProps) => {
  const errorMessageId = `${name}_error`
  const inputProps = {
    ...props,
    id: `inp${name}`,
    name,
    placeholder: label,
    required: true,
    'aria-describedby': errorMessageId,
  }

  const { hasError, handleChange } = useValidation()

  return (
    <>
      <FormMessage type="error" id={errorMessageId} show={hasError} message={errorMessage} />
      <InputWithMaskValidationStyles light={hasLightText}>
        {!mask && <input {...inputProps} onChange={handleChange} />}
        {mask && (
          <MaskedInput
            {...inputProps}
            mask={maskMapper(mask)}
            maskPlaceholder={null}
            onChange={handleChange}
          />
        )}
      </InputWithMaskValidationStyles>
    </>
  )
}

export default InputWithMaskValidation
