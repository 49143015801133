import { suppressOthers } from 'aria-hidden'
import { useCallback, useEffect, type RefObject } from 'react'

export const useSuppressOthers = (elementsRef: RefObject<HTMLElement>[], enabled: boolean) => {
  const getRefsContent = useCallback(() => {
    const allElementsTogether = [] as Element[]

    elementsRef.map((ref) => {
      if (!ref.current) return allElementsTogether
      allElementsTogether.push(ref.current)
    })

    return allElementsTogether
  }, [elementsRef])

  useEffect(() => {
    const refElementsContent = getRefsContent()

    if (refElementsContent && enabled) {
      const undo = suppressOthers(refElementsContent)
      return () => undo()
    }
  }, [elementsRef, enabled, getRefsContent])
}
