import type { AxiosResponse } from 'axios'

import type { ConsultData } from '../../types'
import { ApiserasaPremium } from '../api'

const fetchInitialData = (bifrost: string): Promise<ConsultData> =>
  ApiserasaPremium.get<undefined, AxiosResponse<ConsultData>>('/vcpe/cross/v1/consult/premium', {
    headers: {
      'x-bifrost-authorization': bifrost,
      'x-origin': 'vcp-vce',
    },
  }).then((r) => r.data)

export { fetchInitialData }
