import { useCallback } from 'react'

import { Image } from '@/legacy/components/Image'
import { useSelectIconEvent } from '@/legacy/core/analytics/hooks'

import ImgAppStore from '/public/img-apple-store.png'
import ImgPlayStore from '/public/img-play-store.svg'

import { sanitizeUrl } from '@/legacy/core/utils/dom/security'

import type { FooterModel } from './Footer.model'

type FooterAppsProps = Pick<FooterModel, 'apps'>

export const FooterApps = ({ apps }: FooterAppsProps) => {
  const selectIconEvent = useSelectIconEvent()

  const handleSelectContentDownloadApp = useCallback(
    (storeName: string) => {
      selectIconEvent({
        label: `baixar-app-serasa-${storeName}`,
      })
    },
    [selectIconEvent],
  )

  return (
    <ul className="footer-apps" aria-label="aplicativos celular">
      {apps.map(({ name, url, ariaLabel }, index) => (
        <li key={`app_${index}`}>
          <a
            href={sanitizeUrl(url)}
            aria-label={ariaLabel || name}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleSelectContentDownloadApp(name)}
          >
            <Image
              source={name === 'AppStore' ? ImgAppStore : ImgPlayStore}
              width="110"
              height="36"
              layout="fixed"
              alt=""
            />
          </a>
        </li>
      ))}
    </ul>
  )
}
