import { useEffect, useState } from 'react'

import { hasWindow } from '@/legacy/core/utils/dom/utils'

export const useMedia = (query: string) => {
  const isMatchingMediaAvailable = hasWindow() && typeof window.matchMedia === 'function'
  const initialState = isMatchingMediaAvailable ? window.matchMedia(query).matches : false
  const [isMatchingMedia, setIsMatchingMedia] = useState<boolean>(initialState)

  useEffect(() => {
    if (!isMatchingMediaAvailable) return
    let mounted = true

    const mediaQueryList = window.matchMedia(query)
    const onChange = () => {
      if (!mounted) {
        return
      }

      setIsMatchingMedia(!!mediaQueryList.matches)
    }

    mediaQueryList.addListener(onChange)
    setIsMatchingMedia(!!mediaQueryList.matches)

    return () => {
      mounted = false
      mediaQueryList.removeListener(onChange)
    }
  }, [query, isMatchingMediaAvailable])

  return isMatchingMedia
}
