import { datadogRum } from '@/libs/dd-browser-rum'

import { DD_ENV } from './constants'

class RumReport {
  error(error: Error, context?: object) {
    datadogRum.addError(error, context)
    if (DD_ENV !== 'prd' && process.env.NODE_ENV !== 'test') {
      console.error({ error, context })
    }
  }

  action(name: string, context?: object) {
    datadogRum.addAction(name, context)
  }
}

const rumReport = new RumReport()

export default rumReport
