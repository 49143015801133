import styled from '@emotion/styled'
import cx from 'classnames'
import type { AriaAttributes, PropsWithChildren, ReactNode } from 'react'

import { Icon } from '@/legacy/components/Icon'
import { BodyM } from '@/legacy/components/Typography'

interface MenuItemProps {
  label: ReactNode
  collapse?: boolean
  href?: string
  aAccessProps?: AriaAttributes & {
    onClick?: () => void
  }
  onClick?: () => void
  tabIndex?: number
  className?: string
}

const MenuItemStyles = styled.li<{ collapse?: boolean }>((props) => ({
  position: 'relative',
  zIndex: '0',

  '& .menu-item': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    border: '1px solid transparent',
    borderBottomColor: 'rgba(var(--theme-surface-lightgray))',
    background: 'rgba(var(--theme-surface-snow))',
    cursor: 'pointer',
    zIndex: '1',
  },

  '& .menu-item:focus, & .angle-down': {
    color: 'rgba(var(--theme-primary))',
  },

  ...(props.collapse && {
    overflow: 'hidden',

    '& .angle-down': {
      transition: 'transform ease-in .3s',
    },

    '& > ul .menu-item': {
      paddingLeft: '2rem',
    },
    '& > .menu-item[aria-expanded="false"] + ul': {
      maxHeight: '0',
      transition: 'max-height ease-out 0.3s',
      overflow: 'hidden',
    },
    '& > .menu-item[aria-expanded="true"] + ul': {
      maxHeight: '47.125rem',
      transition: 'max-height ease-in 0.6s',
    },
    '& .menu-item[aria-expanded="true"] > .angle-down': {
      transform: 'rotate(-180deg)',
    },
  }),
}))

const MenuItem = ({
  label,
  children,
  aAccessProps,
  collapse,
  className,
  ...othersProps
}: PropsWithChildren<MenuItemProps>) => {
  const props = {
    ...aAccessProps,
    ...othersProps,
    ...(aAccessProps?.onClick && {
      tag: 'button',
      href: undefined,
    }),
  }

  return (
    <MenuItemStyles collapse={collapse} role="menuitem">
      <BodyM className={cx(className, 'menu-item')} tag="a" dark="medium" {...props}>
        {label}
        {collapse && <Icon icon="angle-down" />}
      </BodyM>

      {children}
    </MenuItemStyles>
  )
}

export default MenuItem
