import { useCallback } from 'react'

import { useSelectLinkEvent } from '@/legacy/core/analytics/hooks'

import { Icon } from '../Icon'

import { EVENTS_ITEMS, type Event } from './constants'
import { Bar, BarContainer, BarLink } from './styles'

export function BarB2B() {
  const selectLinkEvent = useSelectLinkEvent()

  const handleSelectSEventLink = useCallback(
    (event: Event) => selectLinkEvent(event),
    [selectLinkEvent],
  )

  return (
    <Bar aria-label="barra de navegação">
      <BarContainer>
        <div>
          <BarLink
            aria-label="Para pequenas e médias empresas"
            href="https://empresas.serasaexperian.com.br/"
            onClick={() => handleSelectSEventLink(EVENTS_ITEMS.PME)}
          >
            Para PME
          </BarLink>
        </div>
        <div>
          <BarLink
            aria-label="Para empresas"
            href="https://www.serasaexperian.com.br/"
            onClick={() => handleSelectSEventLink(EVENTS_ITEMS.empresas)}
          >
            Para empresas
          </BarLink>
        </div>
        <div>
          <BarLink
            aria-label="Global"
            href="https://www.serasaexperian.com.br/sites-globais/"
            onClick={() => handleSelectSEventLink(EVENTS_ITEMS.global)}
          >
            <Icon icon="global" className="icon__global" />
            <span> Global</span>
          </BarLink>
        </div>
      </BarContainer>
    </Bar>
  )
}
