import {
  Button as PolycupsButton,
  ButtonLink as PolycupsButtonLink,
  type ButtonLinkProps,
  type ButtonProps,
} from '@polycups/react'
import cx from 'classnames'

import { forwardRef, type MouseEvent } from 'react'

import { useSelectButtonEvent } from '@/legacy/core/analytics/hooks'
import { clearBuilderProps } from '@/legacy/core/utils/dom/clearBuilderProps'
import { sanitize } from '@/legacy/core/utils/security'

import buttonUtils from './utils'

type HocProps<P, P1, P2> = P extends P1 ? P1 : P2
type HocButtonElementProps<P> = HocProps<P, HTMLAnchorElement, HTMLButtonElement>
export type HocButtonProps<P> = HocProps<P, ButtonLinkProps, ButtonProps>

export const BUTTON_SIZE = {
  s: 'sm',
  m: 'md',
  l: 'lg',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
} as const

PolycupsButton.propTypes = {}
PolycupsButtonLink.propTypes = {}

function buttonHOC<P>(name: string, options?: Partial<HocButtonProps<P>>) {
  const Button = forwardRef<HocButtonElementProps<P>, HocButtonProps<P>>(
    (
      {
        value,
        size,
        children,
        onClick,
        rounded = false,
        event,
        eventHook: useEvent = useSelectButtonEvent,
        modifier,
        source,
        ...otherProps
      },
      ref,
    ) => {
      const { getEventLabel, getHandleClickByAction } = buttonUtils
      const props = {
        ...options,
        ...otherProps,
        size: size && BUTTON_SIZE[size as keyof typeof BUTTON_SIZE],
        onClick,
        modifier,
        ref,
        className: cx(otherProps.className, {
          'ea-button--rounded': rounded,
        }),
      }

      const action = props.action

      // Clean useless props
      delete props.action
      clearBuilderProps(props)

      const handleEvent = useEvent({ ...event })
      const handleClick = getHandleClickByAction({ onClick, action, value })

      props.onClick = (ev: MouseEvent) => {
        handleEvent({ ...event, label: event?.label || getEventLabel({ children, event: ev }) })
        handleClick(ev)
      }

      if (!children) {
        return null
      }

      if (action === 'link') {
        const link = source || (props as ButtonLinkProps).href || value || ''
        return (
          <PolycupsButtonLink {...props} role="button" href={sanitize(link)}>
            {children}
          </PolycupsButtonLink>
        )
      }

      return <PolycupsButton {...props}>{children}</PolycupsButton>
    },
  )

  Button.displayName = name

  return Button
}

export const ButtonLink = buttonHOC<ButtonLinkProps>('ButtonLink', {
  action: 'link',
})

export const Button = buttonHOC<ButtonProps>('Button', {
  type: 'button',
})
