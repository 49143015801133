import { RumReport } from '@/legacy/core/monitoring'

interface Props {
  error: Error
  context?: object
}

export const sendError = ({ error, context = {} }: Props) => {
  const isProd = process.env.NODE_ENV === 'production'
  try {
    RumReport.error(error, context)
  } catch (error) {
    if (!isProd) console.error(error)
  }
}
