import { css, Global } from '@emotion/react'
import { toCustomPropertiesString } from 'object-to-css-variables'

import { CONFIG } from './emotion.config'

const cssVariables = toCustomPropertiesString(CONFIG.theme)

export const globalStyles = (
  <Global
    styles={css`
      :root {
        --theme-rounded-border-radius: 1.5rem;
        --theme-border-radius: var(--radii-default, 0.625rem);
        --theme-box-shadow: var(--shadows-default, 0 1px 4px 0 rgba(91, 98, 122, 0.3));
        --theme-transition: var(--transitions-default, 0.3s ease);
        ${cssVariables}
      }

      html,
      body {
        font-size: 16px;
        -webkit-text-size-adjust: 100%;
        font-family:
          Roboto,
          -apple-system,
          BlinkMacSystemFont,
          Segoe UI,
          Oxygen,
          Ubuntu,
          Cantarell,
          Fira Sans,
          Droid Sans,
          Helvetica Neue,
          sans-serif;
      }

      body {
        background-color: var(--colors-body-background, transparent);
        color: rgba(var(--theme-text-dark-high));
        -webkit-tap-highlight-color: transparent;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      hr {
        background-color: rgba(var(--theme-surface-lightgray));
        border-style: none;
        height: 0.125rem;
        width: 100%;
      }

      .ea-button {
        border-radius: var(--theme-border-radius);
      }

      .ea-button--solid-invert {
        color: rgba(var(--theme-primary));
        background-color: rgba(var(--theme-snow));
      }

      .ea-button--solid-invert:active,
      .ea-button--solid-invert:focus,
      .ea-button--solid-invert:hover {
        box-shadow: 0 0 0 4px rgba(var(--theme-snow), 0.24);
      }

      .ea-button--solid-invert:active {
        border-color: hsla(0, 0%, 100%, 0.5);
        background-color: rgba(var(--theme-snow));
        box-shadow: 0 0 0 3px rgba(var(--theme-snow), 0.33);
      }

      .ea-button--link {
        display: inline;
        color: rgba(var(--theme-primary));
        text-decoration: underline;
        padding: 0.2rem 0.1rem;
        border: 0;
        margin: 0;
      }

      .ea-button--link:focus,
      .ea-button--link:active,
      .ea-button--link:hover {
        color: rgba(var(--theme-accent-300));
      }

      .ea-button--link:focus {
        box-shadow: 0 0 0 4px rgba(var(--theme-accent), 0.24);
        border-radius: var(--theme-border-radius);
      }

      .et-snow {
        color: rgba(var(--theme-snow));
      }
      .et-palegray {
        color: rgba(var(--theme-palegray));
      }
      .et-gray {
        color: rgba(var(--theme-surface-palegray));
      }
      .et-bg-primary {
        background-color: rgba(var(--theme-primary));
      }
      .et-bg-accent {
        background-color: rgba(var(--theme-accent));
      }
      .et-bg-snow {
        background-color: rgba(var(--theme-snow));
      }
      .et-bg-palegray {
        background-color: rgba(var(--theme-palegray));
      }
      .et-bg-gray {
        background-color: rgba(var(--theme-surface-palegray));
      }
      .et-black {
        color: rgba(var(--theme-black));
      }

      .et-pink {
        color: rgba(var(--theme-pink));
      }

      .et-red-ribbon {
        color: rgba(var(--theme-red-ribbon));
      }

      .et-text-light-solid {
        color: rgba(var(--theme-text-light-solid));
      }
      .et-text-light-high {
        color: rgba(var(--theme-text-light-high));
      }

      .et-text-primary {
        color: rgba(var(--theme-primary));
      }

      .ea-typography--content p:not(:last-child) {
        margin-bottom: 1rem;
      }

      .ea-typography--content a {
        color: rgba(var(--theme-primary));
        font-weight: 700;
        text-decoration: none;
      }

      .ea-typography--content a:hover {
        text-decoration: underline;
      }

      .ea-typography--emphasys {
        font-weight: 700;
      }

      .et-ta-center {
        text-align: center;
      }
      .et-ta-left {
        text-align: left;
      }
      .et-ta-right {
        text-align: right;
      }

      .eu-fx-center {
        display: flex;
        margin: auto;
      }

      .eu-al-center {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .et-d-none {
        display: none;
      }

      .ea-button--rounded {
        border-radius: var(--theme-rounded-border-radius);
      }

      @media (min-width: 600px) {
        .et-d-desk-none {
          display: none;
        }
      }
      @media (max-width: 600px) {
        .et-d-mob-none {
          display: none;
        }
      }
      @media (min-width: 960px) {
        :root {
          --space-m: var(--space-5) !important;
          --space-l: var(--space-7) !important;
          --space-b: var(--space-11) !important;
        }
      }

      .ecs-container {
        width: 100%;
        margin: 0 auto;
        padding: 0rem 1rem;
        max-width: 960px;
      }
      .eu-sp-s,
      .eu-sp {
        padding-top: var(--space-s);
        padding-bottom: var(--space-s);
      }
      .eu-sp-m {
        padding-top: var(--space-m);
        padding-bottom: var(--space-m);
      }
      .eu-sp-l {
        padding-top: var(--space-l);
        padding-bottom: var(--space-l);
      }
      .eu-sm-s,
      .eu-sm {
        margin-top: var(--space-s);
        margin-bottom: var(--space-s);
      }
      .eu-sm-m {
        margin-bottom: var(--space-m);
      }
      .eu-sm-l {
        margin-bottom: var(--space-l);
      }
      .eu-sp-0 {
        padding-bottom: 0;
      }
      .eu-sp-1 {
        padding-bottom: var(--space-1);
      }
      .eu-sp-2 {
        padding-bottom: var(--space-2);
      }
      .eu-sp-3 {
        padding-bottom: var(--space-3);
      }
      .eu-smt-0 {
        margin-top: 0;
      }
      .eu-smt-1 {
        margin-top: var(--space-1);
      }
      .eu-smt-2 {
        margin-top: var(--space-2);
      }
      .eu-smt-3 {
        margin-top: var(--space-3);
      }

      .eu-sm-0-r,
      .eu-sm-0 {
        margin-bottom: 0;
      }
      .eu-sm-1-r,
      .eu-sm-1 {
        margin-bottom: var(--space-1);
      }
      .eu-sm-2-r,
      .eu-sm-2 {
        margin-bottom: var(--space-2);
      }
      .eu-sm-3-r,
      .eu-sm-3 {
        margin-bottom: var(--space-3);
      }
      .eu-sm-4-r,
      .eu-sm-4 {
        margin-bottom: var(--space-4);
      }
      .eu-sm-5 {
        margin-bottom: var(--space-5);
      }

      @media (min-width: 63.5rem) {
        .eu-sm-0-r {
          margin-bottom: var(--space-1);
        }
        .eu-sm-1-r {
          margin-bottom: var(--space-2);
        }
        .eu-sm-2-r {
          margin-bottom: var(--space-3);
        }
        .eu-sm-3-r {
          margin-bottom: var(--space-4);
        }
        .eu-sm-4-r {
          margin-bottom: var(--space-6);
        }
      }
    `}
  />
)
