import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import type { AppProps } from 'next/app'

import { AppErrorBoundary } from '@/legacy/components/AppErrorBoundary'
import { MonitoringService } from '@/legacy/core/monitoring'
import type { SampleRate } from '@/legacy/core/monitoring/service'
import { hasDocument } from '@/legacy/core/utils/dom/utils'
import type { NextPageWithLayout } from '@/legacy/models/NextPageWithLayout'
import { DeviceProvider } from '@/ui/shared/providers'

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (hasDocument()) MonitoringService.init(pageProps.sampleRates as SampleRate)

  const cache = createCache({ key: 'epp' })

  const getLayout = Component.getLayout || ((page) => page)

  return (
    <AppErrorBoundary {...pageProps}>
      <CacheProvider value={cache}>
        <DeviceProvider device={pageProps.device}>
          {getLayout(<Component {...pageProps} />)}
        </DeviceProvider>
      </CacheProvider>
    </AppErrorBoundary>
  )
}

export default MyApp
