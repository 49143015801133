import { createContext, useCallback, useRef, type PropsWithChildren } from 'react'

import type { BaseEventProps, EventAnalytics, EventProps, GetEvent } from './models'
import {
  CONTENT_TYPE,
  EVENT_TYPE,
  dataLayerBaseContentGenerator,
  dataLayerContentGenerator,
  dataLayerViewContentGenerator,
  getArea,
} from './utils'

type Event = Pick<EventAnalytics, 'verticalProduct' | 'position'> & {
  url: string
  isLoggedIn?: boolean
  shouldRemoveSecondPartOfPath?: boolean
}

const useAnalyticsProvider = ({
  verticalProduct,
  position,
  url,
  isLoggedIn = false,
  shouldRemoveSecondPartOfPath = true,
}: Event) => {
  const idRef = useRef(0)
  const eventRef = useRef<EventAnalytics>({
    position: position || 'meio',
    ambiente: isLoggedIn ? 'logado' : 'deslogado',
    verticalProduct,
    area: getArea({ verticalProduct, url, shouldRemoveSecondPartOfPath }),
  })

  const generateId = useCallback(() => {
    idRef.current = idRef.current + 1
    if (idRef.current < 10) return `0${idRef.current}`
    return `${idRef.current}`
  }, [])

  const getEventProps: <T>(props: T) => T & EventAnalytics = useCallback(
    (props) => ({
      ...eventRef.current,
      area: getArea({
        verticalProduct: eventRef.current.verticalProduct,
        url: window.location.pathname,
        shouldRemoveSecondPartOfPath,
      }),
      ...props,
    }),
    [shouldRemoveSecondPartOfPath],
  )

  const baseEvent = useCallback(
    ({ itemId, contentType, eventType, dataLayerGenerator }: BaseEventProps) => {
      const idGenerate = generateId()

      return ({ label, ...overrideProps }: GetEvent) => {
        const props = getEventProps(overrideProps)
        const event = dataLayerGenerator({
          id: props.id || idGenerate,
          label,
          ...props,
          event: eventType,
          contentType: contentType,
          itemId: itemId || props.itemId,
        })

        window.dataLayer.push(event.ga360)
        window.dataLayer.push(event.ga4)
      }
    },
    [generateId, getEventProps],
  )

  const selectContentEvent = useCallback(
    ({ itemId, contentType }: EventProps) =>
      baseEvent({
        itemId,
        contentType,
        eventType: EVENT_TYPE.selectContent,
        dataLayerGenerator: dataLayerContentGenerator,
      }),
    [baseEvent],
  )

  const viewContentEvent = useCallback(
    ({ itemId, contentType }: EventProps) =>
      baseEvent({
        itemId,
        contentType,
        eventType: EVENT_TYPE.viewContent,
        dataLayerGenerator: dataLayerViewContentGenerator,
      }),
    [baseEvent],
  )

  const successEvent = useCallback(
    ({ itemId, contentType }: EventProps) =>
      baseEvent({
        itemId,
        contentType,
        eventType: EVENT_TYPE.success,
        dataLayerGenerator: dataLayerBaseContentGenerator,
      }),
    [baseEvent],
  )

  const selectButtonEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.button }),
    [selectContentEvent],
  )
  const selectTabEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.tab }),
    [selectContentEvent],
  )

  const selectCardEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.card }),
    [selectContentEvent],
  )
  const selectLogoEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.logo }),
    [selectContentEvent],
  )
  const selectIconEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.icon }),
    [selectContentEvent],
  )
  const selectMenuEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.menu }),
    [selectContentEvent],
  )
  const selectVideoEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.video }),
    [selectContentEvent],
  )
  const selectFieldEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.field }),
    [selectContentEvent],
  )
  const selectLinkEvent = useCallback(
    (itemId?: string, id?: string) =>
      selectContentEvent({ itemId, contentType: CONTENT_TYPE.link, id }),
    [selectContentEvent],
  )
  const viewVideoEvent = useCallback(
    (itemId?: string) => viewContentEvent({ itemId, contentType: CONTENT_TYPE.viewVideo }),
    [viewContentEvent],
  )
  const submitSuccessEvent = useCallback(
    (itemId?: string) => successEvent({ itemId, contentType: CONTENT_TYPE.success }),
    [successEvent],
  )

  const viewBannerEvent = useCallback(
    (itemId?: string) => viewContentEvent({ itemId, contentType: CONTENT_TYPE.viewBanner }),
    [viewContentEvent],
  )
  const selectBannerEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.banner }),
    [selectContentEvent],
  )
  const selectSeloEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.selo }),
    [selectContentEvent],
  )

  // TODO: Remover eventos após fim da campanha
  const selectGooglePlayEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.googlePlay }),
    [selectContentEvent],
  )
  const selectAppStoreEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.appStore }),
    [selectContentEvent],
  )

  const viewCardEvent = useCallback(
    (itemId?: string) => viewContentEvent({ itemId, contentType: CONTENT_TYPE.viewCard }),
    [viewContentEvent],
  )

  const selectCloseAppInstallEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.closeAppInstall }),
    [selectContentEvent],
  )

  const selectOpenAppInstallEvent = useCallback(
    (itemId?: string) => selectContentEvent({ itemId, contentType: CONTENT_TYPE.openAppInstall }),
    [selectContentEvent],
  )

  // ---

  return {
    viewCardEvent,
    selectOpenAppInstallEvent,
    selectCloseAppInstallEvent,
    selectGooglePlayEvent,
    selectAppStoreEvent,
    selectBannerEvent,
    selectButtonEvent,
    selectTabEvent,
    selectCardEvent,
    selectLogoEvent,
    selectSeloEvent,
    selectIconEvent,
    selectMenuEvent,
    selectVideoEvent,
    selectFieldEvent,
    selectLinkEvent,
    viewVideoEvent,
    viewBannerEvent,
    submitSuccessEvent,
    verticalProduct,
  }
}

export const AnalyticsContext = createContext({
  viewCardEvent: () => () => {},
  selectOpenAppInstallEvent: () => () => {},
  selectCloseAppInstallEvent: () => () => {},
  selectGooglePlayEvent: () => () => {},
  selectAppStoreEvent: () => () => {},
  selectBannerEvent: () => () => {},
  selectButtonEvent: () => () => {},
  selectTabEvent: () => () => {},
  selectCardEvent: () => () => {},
  selectLogoEvent: () => () => {},
  selectSeloEvent: () => () => {},
  selectIconEvent: () => () => {},
  selectMenuEvent: () => () => {},
  selectVideoEvent: () => () => {},
  selectFieldEvent: () => () => {},
  selectLinkEvent: () => () => {},
  viewVideoEvent: () => () => {},
  viewBannerEvent: () => () => {},
  submitSuccessEvent: () => () => {},
  verticalProduct: () => () => {},
} as unknown as ReturnType<typeof useAnalyticsProvider>)

export const AnalyticsProvider = ({
  event,
  children,
}: PropsWithChildren<{
  event: Event
}>) => (
  <AnalyticsContext.Provider value={useAnalyticsProvider(event)}>
    {children}
  </AnalyticsContext.Provider>
)
