import { removeAccents, toKebabCase } from '@/legacy/core/utils/dom/string'

export const sanitizeText = (str: string) => toKebabCase(removeAccents(str))

export const sanitizeObject = (unsanitized: { [key: string]: string | number | boolean }) => {
  if (!unsanitized) return
  const sanitized: typeof unsanitized = {}

  for (const [key, value] of Object.entries(unsanitized)) {
    if (!(typeof value === 'string')) {
      sanitized[key] = value
      continue
    }
    sanitized[key] = sanitizeText(value)
  }

  return sanitized
}
