import { BodyP } from '../Typography'
import { VisuallyHidden } from '../VisuallyHidden'

import type { FooterModel } from './Footer.model'

type FooterAddressesProps = Pick<FooterModel, 'address'>

export const FooterAddresses = ({ address }: FooterAddressesProps) => (
  <>
    <BodyP tag="p" className="eu-sm-1">
      {address.listTitle}
    </BodyP>
    <ul aria-label="endereços da Serasa">
      {address.listAddresses.map(({ content, ariaLabel }, index) => (
        <li className="address-content" key={`foad_${index}`}>
          <VisuallyHidden>{ariaLabel}</VisuallyHidden>
          <p className="footer-address" aria-hidden={!!ariaLabel}>
            {content}
          </p>
        </li>
      ))}
    </ul>
  </>
)
