import { useEffect, type RefObject } from 'react'
import 'intersection-observer'

interface UseIsContentInViewportProps {
  ref: RefObject<HTMLElement | null>
  callback: () => void
}

export const useIsContentInViewport = ({ ref, callback }: UseIsContentInViewportProps) => {
  useEffect(() => {
    if (!ref.current) return
    const elementsObserver = new IntersectionObserver(
      ([elementEntry]) => elementEntry.isIntersecting && callback(),
      { threshold: 1 },
    )
    elementsObserver.observe(ref.current)
    return () => {
      elementsObserver.disconnect()
    }
  }, [ref, callback])
}
