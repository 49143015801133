import { createContext, useContext, type PropsWithChildren } from 'react'

interface BlogProvider {
  isBlogPage?: boolean
}

export const BlogContext = createContext<BlogProvider>({ isBlogPage: false })

export const BlogProvider = ({ isBlogPage = true, children }: PropsWithChildren<BlogProvider>) => (
  <BlogContext.Provider value={{ isBlogPage }}>{children}</BlogContext.Provider>
)

export const useBlogContext = () => useContext(BlogContext)
