import { useCallback, useRef, type MutableRefObject } from 'react'

import { useIsContentInViewport } from '../useIsContentInViewport'

interface UseViewContentEvent {
  contentRef: MutableRefObject<HTMLElement | null>
  viewContentEvent: () => void
}

export const useViewContentEvent = ({ contentRef, viewContentEvent }: UseViewContentEvent) => {
  const eventSent = useRef<boolean>(false)

  const handleContentInViewport = useCallback(() => {
    if (!eventSent.current) {
      viewContentEvent()
      eventSent.current = true
    }
  }, [viewContentEvent])

  useIsContentInViewport({
    ref: contentRef,
    callback: handleContentInViewport,
  })
}
