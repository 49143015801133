import { useCallback, useState } from 'react'

import { useSelectMenuEvent } from '@/legacy/core/analytics/hooks'
import { sanitize } from '@/legacy/core/utils/security'

import MenuItem from '../Item'
import MenuList from '../List'

type Item = {
  label: string
  href: string
  items?: Item[]
}

export interface SubMenuProps {
  label: string
  href: string
  items?: Item[] | null
  collapse?: boolean
  tabIndex?: number
}

const Submenu = ({ label, href, items = null, tabIndex, collapse }: SubMenuProps) => {
  const [openMenu, setOpenMenu] = useState(false)

  const selectMenuEvent = useSelectMenuEvent()

  const aAccessProps = {
    role: 'menuitem',
    ...(collapse && {
      onClick: () => setOpenMenu((state) => !state),
      'aria-haspopup': true,
      'aria-expanded': openMenu,
    }),
  }

  const itemProps = {
    collapse,
    aAccessProps,
    tabIndex,
    label,
  }

  const handleSelectContentMenuItem = useCallback(() => {
    if (!label) return
    selectMenuEvent({ label })
  }, [label, selectMenuEvent])

  return (
    <>
      {items && items.length > 0 ? (
        <MenuItem {...itemProps}>
          <MenuList role="menu" ariaLabel={label} aria-hidden={!openMenu}>
            {items.map((props, index) => (
              <Submenu
                key={`subm-${index}`}
                collapse={collapse}
                tabIndex={!openMenu ? -1 : undefined}
                {...props}
              />
            ))}
          </MenuList>
        </MenuItem>
      ) : (
        <MenuItem
          href={sanitize(href)}
          label={label}
          tabIndex={tabIndex}
          onClick={handleSelectContentMenuItem}
        />
      )}
    </>
  )
}

export default Submenu
