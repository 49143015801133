import styled from '@emotion/styled'

import { ButtonLink } from '@/legacy/components/Button'
import { decodeURL } from '@/legacy/core/utils/dom/decodeURL'
import type { LinkProps } from '@/legacy/models'

const HeaderCtaStyles = styled.div({
  background: 'currentColor',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  '& .cta': {
    width: 'calc(50% - 0.5rem)',
  },
})

interface HeaderCtaProps {
  signIn: LinkProps
  signUp: LinkProps
}

const HeaderCta = ({ signIn, signUp }: HeaderCtaProps) => (
  <HeaderCtaStyles className="et-surface-palegray">
    <ButtonLink source={decodeURL(signUp.href)} modifier="ghost" className="cta singUp">
      Cadastrar
    </ButtonLink>
    <ButtonLink source={decodeURL(signIn.href)} modifier="solid" className="cta singIn">
      Entrar
    </ButtonLink>
  </HeaderCtaStyles>
)

export default HeaderCta
