import styled from '@emotion/styled'
import { useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { Menu, type MenuProps } from '@/legacy/components/Menu'
import { SearchBar } from '@/legacy/components/SearchBar'
import { useBlogContext } from '@/legacy/core/blog'
import { useEscKey } from '@/legacy/hooks/useEscKey'
import { useSuppressOthers } from '@/legacy/hooks/useSuppressOthers'
import type { LinkProps } from '@/legacy/models'
import { COMMON_ANIMATION_ATTRIBUTES, CONFIG, slideInLeft, slideOutLeft } from '@/ui/shared/styles'

import SidebarButtonClose from './ButtonClose'
import HeaderCta from './Cta'
import SidebarMask from './Mask'
import HeaderProducts, { type HeaderProductsProps } from './Products'

const HeaderSidebarStyles = styled.div<
  Record<'open' | 'hidden', boolean> & { sideBarState: 'close' | 'open' | 'animating' }
>((props) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  maxHeight: '100vh',
  height: '100vh',
  backgroundColor: '#efefef',
  minWidth: '2.75rem',
  outline: 'none',
  overflowX: 'hidden',
  overflowY: 'auto',
  zIndex: '1047',

  ...COMMON_ANIMATION_ATTRIBUTES,
  animationName: `${slideInLeft}`,

  width: '100%',
  maxWidth: '27rem',
  background: 'rgba(var(--theme-snow))',

  [CONFIG.media.lg]: {
    maxWidth: '22.5rem',
  },

  ...(props.sideBarState === 'open' && {
    transform: 'none',
    animation: 'none',
  }),

  ...(!props.open && {
    animationName: `${slideOutLeft}`,
  }),

  ...(props.hidden && {
    display: 'none',
  }),
}))

const SearchBarComponent = styled(SearchBar)({
  padding: '1.5rem 0.5rem',
})

export interface HeaderSidebarProps extends Pick<HeaderProductsProps, 'products'> {
  signUp: LinkProps & { label: string }
  signIn: LinkProps & { label: string }
  open: boolean
  onClickMenu: () => void
  menus: MenuProps['menus']
}

const HeaderSidebar = ({
  open,
  signIn,
  signUp,
  menus,
  products,
  onClickMenu,
}: HeaderSidebarProps) => {
  const { isBlogPage } = useBlogContext()
  const [sideBarState, setSideBarState] = useState<'close' | 'open' | 'animating'>('close')
  const hidden = sideBarState === 'close'
  const { ref: HeaderSidebarRef } = useEscKey(onClickMenu)
  const sidebarMaskRef = useRef(null)

  useUpdateEffect(() => {
    setSideBarState('animating')
  }, [open])

  useSuppressOthers([HeaderSidebarRef, sidebarMaskRef], !hidden)

  return (
    <>
      <HeaderSidebarStyles
        id="sidebar"
        ref={HeaderSidebarRef}
        open={open}
        sideBarState={sideBarState}
        onAnimationEnd={() => setSideBarState(open ? 'open' : 'close')}
        aria-hidden={hidden || undefined}
        hidden={hidden}
        tabIndex={hidden ? -1 : 0}
      >
        <SidebarButtonClose open={open} onClick={onClickMenu} />
        <HeaderCta signIn={signIn} signUp={signUp} />
        {isBlogPage && (
          <SearchBarComponent
            required
            placeholder="Pesquise artigos em nosso blog"
            colorModifier="ghost"
            buttonLabel="Pesquisar"
            onClose={onClickMenu}
          />
        )}
        <Menu menus={menus}>
          <HeaderProducts products={products} />
        </Menu>
      </HeaderSidebarStyles>
      <SidebarMask
        ref={sidebarMaskRef}
        onClickMask={onClickMenu}
        open={open}
        sideBarState={sideBarState}
        hidden={hidden}
      />
    </>
  )
}

export default HeaderSidebar
