/* eslint-disable @typescript-eslint/no-explicit-any */
export function hasDocument() {
  return typeof document !== 'undefined'
}

export function hasWindow() {
  return typeof window !== 'undefined'
}

export function checkIsTouchDevice() {
  return hasDocument() && 'ontouchstart' in document.documentElement
}

export function checkPassiveEventSupport() {
  let supportsPassive = false
  try {
    const opts = Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true
      },
    })

    if (!hasWindow()) {
      return false
    }

    const testPassive = () => true

    window.addEventListener('testPassive', testPassive, opts)
    window.removeEventListener('testPassive', testPassive, opts)

    // eslint-disable-next-line no-empty
  } catch (e) {}

  return supportsPassive
}

export function getPageUrl() {
  if (!hasWindow()) {
    return ''
  }
  const wl = window.location
  return wl.protocol + '//' + wl.host + wl.pathname
}

export type ElementPositionType = { y: number; x: number }

/**
 * Retornar posição x/y em relação ao Body
 * @param el Element
 */
export function elementPosition<T extends Element = Element>(el: T): ElementPositionType {
  const rect = el.getBoundingClientRect()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const scrollLeftParent = el.parentElement!.getBoundingClientRect()

  return { y: rect.top, x: rect.left - scrollLeftParent.left }
}

type addEventListenerPassive = <K extends keyof DocumentEventMap>(
  type: K,
  listener: (this: Document, evt: DocumentEventMap[K]) => any,
  element: any,
) => void

export const addEventListenerPassive: addEventListenerPassive = (type, listener, element) => {
  if (checkPassiveEventSupport()) {
    return element?.addEventListener(type, listener, { passive: true })
  }

  return element?.addEventListener(type, listener)
}

export function getPathname() {
  if (typeof window === 'undefined') return ''
  return window.location.pathname
}
