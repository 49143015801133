export const scrollToIntoView = <T extends Element = Element>({
  selector,
  element,
  options = { behavior: 'smooth', block: 'start' },
}: {
  selector?: string | undefined
  element?: T
  options?: boolean | ScrollIntoViewOptions
}) => {
  if (!selector && !element) {
    return
  }

  const section = element || document.querySelector(`${selector}`)
  if (section) {
    section.scrollIntoView(options)
  }
}

const scrollCalc = (el: HTMLElement | undefined | null): number | undefined => {
  if (!el || !el.parentElement) {
    return
  }

  const elLeft = el.offsetLeft + el.offsetWidth
  const elParentLeft = el.parentElement.offsetLeft + el.parentElement.offsetWidth

  // Next
  if (elLeft >= elParentLeft + el.parentElement.scrollLeft) {
    return elLeft - elParentLeft
  }

  // Prev
  if (elLeft <= el.parentElement.offsetLeft + el.parentElement.scrollLeft) {
    return el.offsetLeft - el.parentElement.offsetLeft
  }
}

export const scrollTo = (
  el: HTMLElement | undefined | null,
  direction: 'left' | 'center' = 'left',
) => {
  if (!el || !el.parentElement) {
    return
  }

  const scrollLeft = scrollCalc(el)

  if (direction === 'center') {
    el.parentElement.scrollLeft = scrollLeft || el.offsetLeft - el.parentElement.offsetLeft
    return
  }

  el.parentElement.scrollLeft = scrollLeft || el.offsetLeft
}
