const formatCurrency = (value: string | number, fractionDigits = 2) =>
  Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(parseFloat(`${value}`))

const currencyToNumber = (value: string): number => Number(value.replace(/\D/g, '')) / 100

export { currencyToNumber, formatCurrency }
