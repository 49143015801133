/* eslint-disable react/react-in-jsx-scope */
import { Component } from 'react'

import { sendError } from '@/legacy/core/error'
import Error, { type ErrorPageProps } from '@/pages/_error'

class AppErrorBoundary extends Component<Partial<ErrorPageProps>, { hasError: boolean }> {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    sendError({ error })
  }

  render() {
    return this.state?.hasError ? <Error errorCode={500} {...this.props} /> : this.props.children
  }
}

export default AppErrorBoundary
