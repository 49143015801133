import type { ImageLoaderProps } from 'next/legacy/image'

type BuilderLoaderProps = ImageLoaderProps & {
  hasError?: boolean
}

export function builderLoader({ src, width, hasError = false }: BuilderLoaderProps) {
  if (typeof src !== 'string') return src

  if (hasError || isGif(src)) return src

  if (!isFromBuilderCdnAndIsNotOptimizedYet(src)) return src

  const srcWithOptimizedFormat = getSrcWithOptimizedFormat(src)

  try {
    const srcWithOptimizedFormatAndWidth = getSrcWithOptimizedWidth(srcWithOptimizedFormat, width)
    return srcWithOptimizedFormatAndWidth
  } catch (e) {
    return srcWithOptimizedFormat
  }
}

const isGif = (src: string) => src.includes('/file/') || src.includes('.gif')

const isFromBuilderCdnAndIsNotOptimizedYet = (src: string) =>
  src.match(/cdn.builder\.io/) && !src.includes('format=')

const getSrcWithOptimizedFormat = (src: string) =>
  src.includes('?') ? `${src}&format=webp` : `${src}?format=webp`

const getSrcWithOptimizedWidth = (srcWithOptimizedFormat: string, width: string | number) => {
  let normalizedWidth: number
  if (typeof width === 'string') {
    const widthWithoutPx = (width as string).replace('px', '')
    normalizedWidth = Number(widthWithoutPx)
  } else {
    normalizedWidth = width
  }
  return `${srcWithOptimizedFormat}&width=${normalizedWidth * 2}`
}
