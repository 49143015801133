export const decodeURL = (text: string | undefined) => {
  let newText = ''
  const entities = [['amp', '&']]

  if (!text) {
    return ''
  }

  for (let i = 0, max = entities.length; i < max; i += 1)
    newText = text.replace(new RegExp(`&${entities[i][0]};`, 'g'), entities[i][1])

  return newText
}
