import styled from '@emotion/styled'

import { CONFIG } from '@/ui/shared/styles'

export const Bar = styled.nav`
  border-top: 1px solid rgba(var(--theme-surface-silver));
  background-color: #fff;
`

export const BarContainer = styled.div`
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
  min-height: 2rem;
  max-width: ${CONFIG.maxWidths.md};
  margin: auto;

  div {
    color: rgba(var(--theme-text-dark-medium));
  }
  a:last-child {
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .icon__global svg {
    width: 1rem;
    height: 1rem;
  }

  ${CONFIG.media.mobileLg} {
    div:first-of-type,
    div:nth-of-type(2) {
      flex-grow: 1;
      border-right: 1px solid rgba(var(--theme-surface-silver));
    }

    div:last-child {
      flex-grow: 0.5;
      span {
        display: none;
      }
    }
    gap: 0;
  }
`
export const BarLink = styled.a`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  font-size: 0.75rem;
  line-height: 1.33;
`
