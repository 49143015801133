import type { FooterModel } from '@/legacy/components/Footer/Footer.model'
import { FooterStyles } from '@/legacy/components/Footer/Footer.styles'

import { FooterAddresses } from './FooterAddresses'
import { FooterApps } from './FooterApps'
import { FooterExtraLinks } from './FooterExtraLinks'
import { FooterSitemap } from './FooterSitemap'
import { FooterSocialNetworks } from './FooterSocialNetworks'
import FooterStamp from './FooterStamp'

export type FooterProps = FooterModel

const Footer = ({ column, socialNetworks, apps, extraLinks, address }: FooterProps) => (
  <FooterStyles id="main-footer">
    <div className="footer-container">
      {column && <FooterSitemap column={column} />}
      <div className="footer-etc">
        {socialNetworks && <FooterSocialNetworks socialNetworks={socialNetworks} />}
        {apps && <FooterApps apps={apps} />}
        <FooterStamp />
      </div>
      {extraLinks && <FooterExtraLinks extraLinks={extraLinks} />}
      {address && <FooterAddresses address={address} />}
    </div>
  </FooterStyles>
)

export default Footer
