import { useState } from 'react'

import { CookieStorage } from '@/legacy/core/utils/cookies'

import { APP_INSTALL_BANNER_COOKIE_KEY } from './constants'

const useShowAppInstallBanner = () => {
  const [isActive, setIsActive] = useState(true)

  const hideBanner = () => {
    CookieStorage.set(APP_INSTALL_BANNER_COOKIE_KEY, 'true')
    setIsActive(false)
  }

  return {
    hideBanner,
    isActive,
  }
}

export default useShowAppInstallBanner
