export type ErrorArgs = [string?, ErrorOptions?]

export class BaseException extends Error {
  status: number
  code: string
  details?: {
    [key in string]: string
  }

  constructor(...args: ErrorArgs) {
    super(...args)
    this.status = 500
    this.code = 'generic-error'
    this.message = 'Ocorreu um erro interno'
  }

  toJSON() {
    return {
      code: this.code,
      status: this.status,
      message: this.message,
      details: this.details,
    }
  }
}

export type InternalException = {
  code: string
  message: string
  status: string
  details?: {
    [key in string]: string
  }
}
