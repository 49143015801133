import { useState, useCallback, useRef } from 'react'

import { useSuppressOthers } from '@/legacy/hooks/useSuppressOthers'

export const useSearchSection = () => {
  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(false)
  const handleToggleSearchBar = useCallback(() => setIsSearchBarOpen((state) => !state), [])
  const handleCloseSearchBar = useCallback(() => setIsSearchBarOpen(false), [])

  const topHeaderRef = useRef<HTMLDivElement>(null)
  const searchSectionRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  useSuppressOthers([topHeaderRef, searchSectionRef], isSearchBarOpen)

  return {
    isSearchBarOpen,
    handleToggleSearchBar,
    handleCloseSearchBar,
    topHeaderRef,
    buttonRef,
  }
}
