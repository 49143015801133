/* eslint-disable @typescript-eslint/no-explicit-any */
export type BuilderProps = Partial<{
  builderBlock: any
  builderState: any
  builderContext: any
  children: any
  attributes: any
}>

/**
 * Remove props que são recebidas quando configurado com Builder.registerComponent
 * Todos os componentes Registrados no Buider recebem props que não devem ser repassadas para Tags HTML.
 * @param props any
 */
export function clearBuilderProps<T>(props: T & BuilderProps, propsToDelete: Array<string> = []) {
  delete props.builderBlock
  delete props.builderState
  delete props.builderContext
  delete props.attributes

  for (const prop of propsToDelete) {
    delete (props as any)[prop]
  }
}
