import styled from '@emotion/styled'
import cx from 'classnames'
import { useCallback } from 'react'

import { useUpdateEffect } from 'react-use'

import { Button, ButtonLink } from '@/legacy/components/Button'
import { Icon } from '@/legacy/components/Icon'
import { Image } from '@/legacy/components/Image'
import { CONFIG_PRODUCTS, type ProductKeys } from '@/legacy/components/Products/constants'
import { useSelectIconEvent } from '@/legacy/core/analytics/hooks'

import { useBlogContext } from '@/legacy/core/blog'
import { decodeURL } from '@/legacy/core/utils/dom/decodeURL'
import type { LinkProps } from '@/legacy/models'
import { CONFIG } from '@/ui/shared/styles'

import SearchSection from './SearchSection'
import { useSearchSection } from './useSearchSection'

export const HEADER_HEIGHT = 4

const BASE_URL = process.env.NEXT_PUBLIC_ICONS_HOST

export const TopStyles = styled.div({
  position: 'sticky',
  height: '100%',
  top: '0',
  left: '0',
  zIndex: '1040',

  '& .headerTop': {
    background: 'rgba(var(--theme-primary))',
    width: '100%',
    minHeight: `${HEADER_HEIGHT}rem`,
  },
  '& .ecs-container': {
    display: 'flex',
    padding: '0.75rem 0 0.75rem 0',
  },
  '& .ht-cta': {
    '--theme-primary': 'var(--theme-white)',
    '--theme-primary-300': 'var(--theme-primary)',
    '--theme-primary-700': 'var(--theme-primary)',
    width: '6.25rem',
    fontWeight: 'normal',
    justifyItems: 'flex-end',
  },
  '& .ht-cta:focus, & .ht-cta:active, & .ht-cta:hover': {
    background: 'transparent',
    boxShadow: 'none',
  },
  '& .ht-cta .icon': {
    marginRight: '0.5rem',
  },
  svg: {
    maxWidth: 'fit-content',
  },
  '& .ht__magnifier': {
    fontSize: '1.125rem',
  },
  '& .headerHamburguer': {
    minWidth: '0',
    justifyItems: 'flex-start',
  },
  '& .iconHamburguer:after, & .iconHamburguer:before, & .iconHamburguer > div': {
    content: `''`,
    display: 'block',
    width: '0.75rem',
    height: '2px',
    backgroundColor: 'currentColor',
    borderRadius: '2px',
  },
  '& .iconHamburguer:before, & .iconHamburguer div': {
    margin: '0 0 0.188rem',
  },
  '& .headerLogoMini, & .headerLogo': {
    height: '2rem',
    alignSelf: 'center',
  },
  '& .headerLogo': {
    margin: '0 auto',
  },
  '& .logo-limpa-nome': {
    width: '128px',
  },
  '& .logo-ecred': {
    width: '78px',
  },
  '& .logo-score': {
    width: '86px',
  },
  '& .withMini': {
    margin: '0 auto 0 0',
  },
  '& .headerLogoMini': {
    paddingRight: '1rem',
    margin: '0 1rem 0 auto',
    border: 'solid rgba(var(--theme-white), 0.2)',
    borderWidth: '0 1px 0 0',
    color: 'rgba(var(--theme-white))',
    fontSize: '2rem',
  },
  [CONFIG.media.mobileLg]: {
    '& .headerTop': {
      height: '3rem',
    },
    '& .headerLogoMini': {
      display: 'none',
    },
    '& .headerLogo': {
      margin: 'auto',
      padding: '0',
      border: '0',
    },
  },
})

export const SearchButtonStyles = styled(Button)({
  '--theme-primary': 'var(--theme-white)',
  '--theme-primary-300': 'var(--theme-primary)',
  '--theme-primary-700': 'var(--theme-primary)',

  padding: '0 0.5rem',
  marginRight: '0.5rem',
  minWidth: '1.5rem',

  '&:focus, &:active, &:hover': {
    background: 'transparent',
    boxShadow: 'none',
  },
})

export interface HeaderTopProps {
  open: boolean
  logo: {
    alt: string
    href: string
    img: string
  }
  signIn: LinkProps
  product: ProductKeys
  onClickMenu: () => void
}

const HeaderTop = ({ open, product, signIn, logo, onClickMenu }: HeaderTopProps) => {
  const { isSearchBarOpen, handleToggleSearchBar, handleCloseSearchBar, topHeaderRef, buttonRef } =
    useSearchSection()
  const { isBlogPage } = useBlogContext()
  const selectIconEvent = useSelectIconEvent()
  const productLogo = CONFIG_PRODUCTS[product]
  const withMini = !['serasa', 'carteira-digital'].includes(product) && productLogo

  const handleSelectContentSerasaLogo = useCallback(() => {
    selectIconEvent({ label: 'logo-serasa' })
  }, [selectIconEvent])

  const handleSelectContentProductLogo = useCallback(() => {
    selectIconEvent({ label: `logo-${product}` })
  }, [product, selectIconEvent])

  useUpdateEffect(() => {
    if (!open) {
      setTimeout(() => {
        buttonRef.current?.focus()
      }, 300)
    }
  }, [open])

  const handleOnClickMenu = () => {
    handleCloseSearchBar()
    onClickMenu()
  }

  return (
    <TopStyles id="top-header" aria-hidden={open || undefined} ref={topHeaderRef}>
      <div className="headerTop">
        <div className="ecs-container">
          <Button
            ref={buttonRef}
            modifier="ghost"
            onClick={handleOnClickMenu}
            className="headerHamburguer ht-cta"
            aria-label="Abrir Menu com opções adicionais"
          >
            <div className="icon iconHamburguer">
              <div />
            </div>
            <span>Menu</span>
          </Button>

          {withMini && (
            <a
              href="/"
              className="headerLogoMini"
              aria-label="Ir para página principal da Serasa"
              onClick={handleSelectContentSerasaLogo}
            >
              <Icon icon="serasa" aria-hidden />
            </a>
          )}

          <a
            href={logo.href}
            aria-label={`Ir para página principal ${CONFIG_PRODUCTS[product].name}`}
            className={cx('headerLogo', `logo-${product}`, { withMini: withMini })}
            onClick={handleSelectContentProductLogo}
          >
            <Image
              alt={logo.alt}
              source={!logo.img.includes('https') ? `${BASE_URL}${logo.img}` : logo.img}
              {...(productLogo || CONFIG_PRODUCTS.serasa)}
              layout="fixed"
              aria-hidden
            />
          </a>

          {isBlogPage && (
            <SearchButtonStyles
              modifier="ghost"
              onClick={handleToggleSearchBar}
              aria-label={`${
                isSearchBarOpen ? 'Fechar caixa de pesquisa' : 'Pesquise artigos em nosso blog'
              } `}
            >
              <Icon
                className="ht__magnifier"
                icon={isSearchBarOpen ? 'close' : 'magnifier'}
                aria-hidden
              />
            </SearchButtonStyles>
          )}

          <ButtonLink
            source={decodeURL(signIn.href)}
            modifier="ghost"
            className="ht-cta"
            aria-label="Ir para página de login da Serasa"
          >
            <Icon className="icon" icon="user" aria-hidden />
            Entrar
          </ButtonLink>
        </div>
      </div>
      {isBlogPage && (
        <SearchSection
          open={isSearchBarOpen}
          onClose={handleToggleSearchBar}
          buttonAriaLabel="Pesquisar"
        />
      )}
    </TopStyles>
  )
}

export default HeaderTop
