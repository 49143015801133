import { useCallback } from 'react'

import { Image } from '@/legacy/components/Image'

import { useSelectIconEvent } from '@/legacy/core/analytics/hooks'

import { sanitizeUrl } from '@/legacy/core/utils/dom/security'

import type { FooterModel } from './Footer.model'

type FooterSocialNetworksProps = Pick<FooterModel, 'socialNetworks'>

export const FooterSocialNetworks = ({ socialNetworks }: FooterSocialNetworksProps) => {
  const selectIconEvent = useSelectIconEvent()

  const handleSelectSocialNetworkIcon = useCallback(
    (socialNetworkName: string) => {
      selectIconEvent({
        label: `rede-social-${socialNetworkName}`,
      })
    },
    [selectIconEvent],
  )

  return (
    <ul className="footer-social-networks" aria-label="redes sociais">
      {socialNetworks.map(
        ({ name, url, ariaLabel, image }, index) =>
          image && (
            <li key={`sn_${index}`}>
              <a
                href={sanitizeUrl(url)}
                aria-label={ariaLabel || name}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleSelectSocialNetworkIcon(name)}
              >
                <Image source={image} alt={name} width="28" height="28" />
              </a>
            </li>
          ),
      )}
    </ul>
  )
}
