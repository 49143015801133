import styled from '@emotion/styled'

import { Button } from '@/legacy/components/Button'
import { CONFIG } from '@/ui/shared/styles'

export const ButtonStyles = styled(Button)`
  color: rgba(var(--colors-primary));

  ${CONFIG.media.lg} {
    padding: 1.5rem 0;
  }

  &:focus,
  &:hover,
  &:active {
    background-color: transparent;
    box-shadow: none;
  }

  .iwt__magnifier {
    font-size: 1.125rem;
  }
`
