import type { FooterModel } from './Footer.model'

type FooterExtraLinksProps = Pick<FooterModel, 'extraLinks'>

export const FooterExtraLinks = ({ extraLinks }: FooterExtraLinksProps) => (
  <ul className="footer-extra-links">
    {extraLinks.map((link, i) => (
      <li key={`fol_${link.url}_${i}`}>
        <a href={link.url}>{link.text}</a>
      </li>
    ))}
  </ul>
)
