import { useRouter } from 'next/router'
import { useMemo, useRef } from 'react'

import {
  useSelectCloseAppInstallEvent,
  useSelectOpenAppInstallEvent,
  useViewCardEvent,
} from '@/legacy/core/analytics/hooks'

import type { Device } from '@/legacy/core/utils/getDevice'

import { useViewContentEvent } from '@/legacy/hooks/useViewContentEvent'

import { Button, ButtonLink } from '../Button'

import { Icon } from '../Icon'
import { Image } from '../Image'
import { BodyM, BodyP } from '../Typography'

import { DEEP_LINK_URL, SERASA_APP_ICON } from './constants'
import { getButtonAriaLabel, getStoreName } from './helpers'
import { ContainerStyled } from './styles'
import useShowAppInstallBanner from './useShowBanner'

interface AppInstallBannerProps {
  device?: Device
}

const AppInstallBanner = ({ device }: AppInstallBannerProps) => {
  const router = useRouter()
  const { isActive, hideBanner } = useShowAppInstallBanner()

  const isHomeRouter = useMemo(() => router.asPath === '/', [router.asPath])

  const store = getStoreName(device)
  const viewContentEvent = useViewCardEvent()

  const bannerRef = useRef(null)
  useViewContentEvent({
    contentRef: bannerRef,
    viewContentEvent: () => {
      viewContentEvent({ label: eventLabel })
    },
  })

  if (!isActive || device === 'desktop') return null

  const buttonLabel = device === 'android' ? 'Baixar' : 'Ver'
  const buttonAriaLabel = getButtonAriaLabel(store)

  const description = `Grátis na ${store}`
  const eventLabel = `card-serasa-gratis-na-${store}`

  const hanldeCloseButton = () => {
    hideBanner()
  }

  return (
    <ContainerStyled ref={bannerRef} role="section" aria-label="Baixar aplicativo da serasa">
      <Button
        modifier="ghost"
        aria-label="fechar banner de instalação do aplicativo"
        className="close-button"
        onClick={hanldeCloseButton}
        eventHook={useSelectCloseAppInstallEvent}
        event={{
          label: eventLabel,
          itemId: isHomeRouter ? 'serasa-web_web_botao_meio_01' : undefined,
        }}
      >
        <Icon icon="close" />
      </Button>
      <div className="icon">
        <Image
          alt="Ícone Aplicativo Serasa"
          aria-hidden
          width={48}
          height={48}
          source={SERASA_APP_ICON}
        />
      </div>
      <div className="description">
        <BodyM emphasys>Serasa</BodyM>
        <BodyP>{description}</BodyP>
      </div>
      <ButtonLink
        className="download-button"
        modifier="outline"
        source={DEEP_LINK_URL}
        eventHook={useSelectOpenAppInstallEvent}
        event={{
          label: eventLabel,
          itemId: isHomeRouter ? 'serasa-web_web_botao_meio_02' : undefined,
        }}
        aria-label={buttonAriaLabel}
      >
        {buttonLabel}
      </ButtonLink>
    </ContainerStyled>
  )
}

export default AppInstallBanner
