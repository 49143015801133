import { useState, useEffect } from 'react'
import { useUpdateEffect } from 'react-use'

import { RumReport } from '@/legacy/core/monitoring'

import type { Icon } from './types'
import {
  isCoreIcon as checkIsCoreIcon,
  isLazyIcon as checkIsLazyIcon,
  getIconComponent,
} from './utils'

export const useSvgPath = (iconKey: Icon) => {
  const [path, setPath] = useState(() => getIconComponent(iconKey))
  const isCoreIcon = checkIsCoreIcon(iconKey)
  const isLazyIcon = checkIsLazyIcon(iconKey)
  const hasError = !isCoreIcon && !isLazyIcon

  useEffect(() => {
    if (hasError) {
      const error = new Error('Invalid Icon ' + iconKey)

      if (process.env.NODE_ENV === 'production') {
        RumReport.error(error)
      } else {
        throw error
      }
    }
  }, [iconKey, hasError])

  useUpdateEffect(() => {
    setPath(() => getIconComponent(iconKey))
  }, [iconKey])

  return path
}
