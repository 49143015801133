import { pino } from '@/libs/pino'

import type { LogLevel, LogService } from './types'

export class PinoLogService implements LogService {
  private service = pino({
    browser: {
      write: (obj) => console.log(JSON.stringify(obj)),
    },
    formatters: {
      level: (label) => ({
        level: label,
      }),
    },
  })

  send(code: string, context: object, level: LogLevel) {
    this.service[level]({ code, ...context })
  }
}
