import { datadogLogs } from '@/libs/dd-browser-logs'

import { datadogRum } from '@/libs/dd-browser-rum'

import { hasWindow } from '../../utils/dom/utils'

import { IS_DD_ENABLED } from '../constants'

import type { SampleRate, User } from './types'
import { serviceUtils, shouldStartSessionReplay } from './utils'

function Monitoring() {
  function init(sampleRates?: SampleRate) {
    const isDatadogEnabled = IS_DD_ENABLED === 'true'
    if (!isDatadogEnabled || !serviceUtils.isConfigurationValid() || !hasWindow()) return

    serviceUtils.setupInitialCookie()
    serviceUtils.sendUserOrigin()
    serviceUtils.initializeServices(sampleRates)
  }

  function stopSessionRecording() {
    if (shouldStartSessionReplay) return

    serviceUtils.stopSessionRecording()
  }

  const identifyUser = (user: User) => {
    if (!hasWindow()) return

    const { id } = user

    const userContext = {
      id,
    }

    datadogRum.setUser(userContext)
    datadogLogs.addLoggerGlobalContext('usr', userContext)
  }

  return {
    init,
    stopSessionRecording,
    identifyUser,
  }
}

export default Monitoring()

export * from './types'
