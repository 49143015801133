import styled from '@emotion/styled'

import { forwardRef, type ElementType } from 'react'

import type {
  PolymorphicComponentProps,
  PolymorphicComponentWithRef,
  PolymorphicRef,
} from '@/legacy/models/PolymorphicComponent'

const StyledVisuallyHidden = styled.span`
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
`

const VisuallyHidden: PolymorphicComponentWithRef = forwardRef(
  <T extends ElementType = 'span'>(
    { as, children, ...props }: PolymorphicComponentProps<T>,
    ref?: PolymorphicRef<T>,
  ) => {
    const Component = as
    return (
      <StyledVisuallyHidden as={Component} {...props} ref={ref}>
        {children}
      </StyledVisuallyHidden>
    )
  },
)
VisuallyHidden.displayName = 'VisuallyHidden'
export default VisuallyHidden
