import type { ButtonLinkProps, ButtonProps } from '@polycups/react'
import type { MouseEvent, ReactNode } from 'react'

import { scrollToIntoView } from '@/legacy/core/utils/dom/scrollTo'

const getEventLabel = ({ children, event }: { children: ReactNode; event: MouseEvent }) => {
  const target = event.currentTarget
  const ariaLabel = target.ariaLabel

  let label = target.textContent || ''
  if (!label && typeof children === 'string' && children) {
    label = children
  }

  if (!label && ariaLabel) {
    label = ariaLabel
  }

  return label
}

function getHandleClickByAction<P = ButtonLinkProps | ButtonProps>({
  onClick,
  action,
  value,
}: Pick<
  P extends ButtonLinkProps ? ButtonLinkProps : ButtonProps,
  'action' | 'value' | 'onClick'
>) {
  let handleClick: typeof onClick

  if (action === 'modal' && value) {
    handleClick = () => {
      const modal = document.getElementById(value) as HTMLDialogElement
      if (!modal) {
        return
      }

      if (modal.hasAttribute('open')) {
        modal.removeAttribute('open')
      } else {
        modal.setAttribute('open', '')
      }
    }
  }

  if (action === 'scroll' && value) {
    handleClick = () => {
      document.getElementById(value) && scrollToIntoView({ selector: `[id='${value}']` })
    }
  }

  return (evt: MouseEvent) => {
    if (handleClick) {
      handleClick(evt)
    }

    if (onClick) {
      onClick(evt)
    }
  }
}

const ButtonUtils = {
  getEventLabel,
  getHandleClickByAction,
}

export default ButtonUtils
