import { datadogLogs } from '@/libs/dd-browser-logs'

import type { LogLevel, LogService } from './types'

export class DatadogLogService implements LogService {
  private service = datadogLogs

  send(code: string, context: object, level: LogLevel) {
    this.service.logger.log(
      code,
      {
        code,
        ...context,
      },
      level,
    )
  }
}
