import type { BuilderContent } from '@builder.io/sdk'

import { Footer, type FooterModel } from '@/legacy/components/Footer'

interface FooterProps {
  content?: BuilderContent
}

const FooterBlock = ({ content }: FooterProps) => {
  if (!content) return null
  const footer = content.data as FooterModel
  return <Footer {...footer} />
}

export default FooterBlock
