import router from 'next/router'
import { useCallback, useRef, type FormEvent, type MutableRefObject } from 'react'

import { useSuccessEvent } from '@/legacy/core/analytics/hooks/useSuccessEvent'

const BASE_PATH = '/blog/search'

interface UseBlogSearchProps {
  searchInputRef: MutableRefObject<HTMLInputElement | null>
  handleOnSubmit: (event: FormEvent) => void
}

export const useBlogSearch = (): UseBlogSearchProps => {
  const successEvent = useSuccessEvent()
  const searchInputRef = useRef<HTMLInputElement>(null)

  const clearInputValue = () => {
    if (searchInputRef.current) searchInputRef.current.value = ''
  }

  const handleGAEvents = useCallback(
    (searchTerm: string) => {
      const successLabel = `sucesso-ao-buscar-${searchTerm}`
      successEvent({ label: successLabel })
    },
    [successEvent],
  )

  const handleOnSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      const searchInputValue = searchInputRef.current?.value.trim()
      const isSearchInputEmpty = !searchInputValue || searchInputValue.length === 0
      if (isSearchInputEmpty) return
      handleGAEvents(searchInputValue)
      clearInputValue()
      router.push({ pathname: BASE_PATH, query: { s_query: encodeURI(searchInputValue) } })
    },
    [handleGAEvents],
  )

  return { searchInputRef, handleOnSubmit }
}
