import { useEffect, type RefObject } from 'react'

import { useCallbackWithRefManipulation } from '../useCallbackWithRefManipulation'

export const useForceFocusOnElement = (ref: RefObject<HTMLElement>, enabled = false) => {
  const callbackWithRefManipulation = useCallbackWithRefManipulation()
  useEffect(() => {
    if (!enabled) return
    callbackWithRefManipulation(() => {
      ref.current && ref.current.focus()
    })
  }, [enabled, callbackWithRefManipulation, ref])
}
