import styled from '@emotion/styled'

const IconCloseStyles = styled.div({
  position: 'relative',
  display: 'inline-block',
  width: '1em',
  height: '1em',
  marginRight: '0.5rem',

  '&:hover': {
    opacity: '1',
  },
  '&:before, &:after': {
    content: `''`,
    position: 'absolute',
    left: 'calc(100% - 0.5em - 1px)',
    height: '1em',
    width: '2px',
    borderRadius: '2px',
    background: 'currentColor',
  },
  '&:before': {
    transform: 'rotate(45deg)',
  },
  '&:after': {
    transform: 'rotate(-45deg)',
  },
})

export const IconClose = () => <IconCloseStyles />
