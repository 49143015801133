import { useEffect } from "react"

import { fetchConsultData } from "@/legacy/blocks/Premium/api/fetchConsultData"
import { sha256 } from "@/legacy/core/utils/sha256"

type GtmVCPEProps = {
  title: string
  verticalProduct: string
  area: string
  isLoggedIn: boolean
}

export const GtmVCPE = ({ title, verticalProduct, area, isLoggedIn }: GtmVCPEProps) => {
  const createScript = (id: string, innerHTML: string) => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script')
      script.id = id
      script.innerHTML = innerHTML
      document.body.appendChild(script)
    }
  }

  useEffect(() => {
    fetchConsultData().then((consultData) => {
      const encryptedData = {
        userId: consultData?.userId ?? '',
        email: sha256(consultData?.email ?? ''),
        phone: sha256(`55${consultData?.phone.replace(/\D/g, '')}`),
      }

      if (encryptedData?.userId) {
        createScript('gtm-vcpe', `(function(w,d){
          w[d]=w[d]||[];
          var userData = {
            'user_id': '${encryptedData.userId}',
            'email':'${encryptedData.email}',
            'telefone':'${encryptedData.phone}'
          };
          w[d].push(userData);
        })(window, 'dataLayer');`)
      }

      createScript('gtm-pv', `(function(w,d,l,oa,eet,ed){
        w[d]=w[d]||[];
        var e={'event':'page_view','page_location':l.href,'page_title':'${title}','page_path':l.pathname, 'tipo_projeto': 'web-public-pages-front'};
        w[d].push(oa({},e,ed));
        w[d].push(oa({},e,{'event':e.event+'_ga4'},ed));
        w[d].push(oa({},ed,{'event':eet,'categoria':ed.vertical_produto+'-'+ed.area,'acao':'entrar-tela','rotulo':ed.area}));
        w[d].push(oa({},ed,{'event':eet+'_ga4','item_text':ed.area}));
      })(window,'dataLayer',location,Object.assign,'entrar_tela',{'vertical_produto':'${verticalProduct}','area':'${area}','ambiente': '${isLoggedIn ? 'logado' : 'deslogado'}'});`)
    })
  }, [area, isLoggedIn, title, verticalProduct])

  return null
}
