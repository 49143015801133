import type { BuilderContent } from '@builder.io/sdk'
import type { NextPageContext } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import type { PropsWithChildren } from 'react'

import { FooterBlock } from '@/legacy/blocks/Footer'
import { HeaderBlock } from '@/legacy/blocks/Header'
import { Metatags } from '@/legacy/components/Metatags'
import { PRODUCT_KEYS, type ProductKeys } from '@/legacy/components/Products/constants'
import {
  BUILDER_CONTENT_API_URL,
  BUILDER_FOOTER_MODEL,
  BUILDER_PAGE_MODEL,
  BUILDER_SERASA_PUBLIC_KEY,
} from '@/legacy/config/builder/constants'
import { AnalyticsProvider } from '@/legacy/core/analytics'
import { getProductFromUrl } from '@/legacy/core/utils/getProductFromUrl'
import type { AnalyticsData, BuilderPageModel } from '@/legacy/models'

const getUrlPathError = (errorCode: number, product: ProductKeys) =>
  `/builder/error/${product || PRODUCT_KEYS.serasa}/${errorCode}/`

export type ErrorPageProps = PropsWithChildren<{
  errorCode: number
  refreshUrl?: string
  product?: ProductKeys | null
  page?: BuilderPageModel
  footer?: BuilderContent
  urlPath?: string
}>

const ErrorContent = dynamic(() => import('../legacy/components/ErrorContent/ErrorContent'), {
  loading: () => <p>Ocorreu um erro</p>,
})

const Theme = dynamic(() => import('../legacy/components/Theme/Theme'), {
  loading: () => <div></div>,
})

const Error = ({ errorCode, refreshUrl, page, footer }: ErrorPageProps) => {
  const router = useRouter()

  if (errorCode === 500 || !page || !page.data) {
    const url = refreshUrl || router.asPath

    const getAnalytics = () => {
      const GTM_CODE = process.env.NEXT_PUBLIC_GTM_CODE as string
      const GTM_VERTICAL_PRODUCT = 'serasa-web'

      return {
        gtmCode: {
          code: GTM_CODE,
        },
        verticalProduct: GTM_VERTICAL_PRODUCT,
      }
    }

    return (
      <AnalyticsProvider event={{ ...getAnalytics(), url, position: 'meio' }}>
        <ErrorContent errorCode={errorCode} refreshUrl={url} />
      </AnalyticsProvider>
    )
  }

  const url = refreshUrl || page.data.url || router.asPath
  const analytics: AnalyticsData = page.data.analytics
  const data = page.data

  return (
    <Theme data={data}>
      <Metatags data={{ ...data, robots: false }} />
      <AnalyticsProvider event={{ ...analytics, url, position: 'topo' }}>
        <HeaderBlock data={data} />
      </AnalyticsProvider>
      <AnalyticsProvider event={{ ...analytics, url, position: 'meio' }}>
        <ErrorContent errorCode={errorCode} refreshUrl={refreshUrl} />
      </AnalyticsProvider>
      {!!footer && (
        <AnalyticsProvider event={{ ...analytics, url, position: 'rodape' }}>
          <FooterBlock content={footer} />
        </AnalyticsProvider>
      )}
    </Theme>
  )
}

Error.getInitialProps = async ({ res, req, err }: NextPageContext) => {
  let errorCode = 404
  if (res?.statusCode) {
    errorCode = res.statusCode
  } else if (err) {
    errorCode = 500
  }
  try {
    const product = getProductFromUrl(req?.url)
    const urlPath = getUrlPathError(errorCode, product)

    const PAGE_CDN_URL = `${BUILDER_CONTENT_API_URL}/${BUILDER_PAGE_MODEL}?apiKey=${BUILDER_SERASA_PUBLIC_KEY}&userAttributes.urlPath=${urlPath}&query.data.product=${PRODUCT_KEYS.serasa}&enrich=true`
    const PRODUCT_PAGE_CDN_URL = `${BUILDER_CONTENT_API_URL}/${BUILDER_PAGE_MODEL}?apiKey=${BUILDER_SERASA_PUBLIC_KEY}&userAttributes.urlPath=${urlPath}&query.data.product=${product}&enrich=true`
    const FOOTER_CDN_URL = `${BUILDER_CONTENT_API_URL}/${BUILDER_FOOTER_MODEL}?apiKey=${BUILDER_SERASA_PUBLIC_KEY}&userAttributes.urlPath=${urlPath}&enrich=true`

    const [pageResponse, productPageResponse, footerResponse] = await Promise.all([
      (await fetch(PAGE_CDN_URL)).json(),
      (await fetch(PRODUCT_PAGE_CDN_URL)).json(),
      (await fetch(FOOTER_CDN_URL)).json(),
    ])

    const pageProps =
      getFirstBuilderEntry(pageResponse) || getFirstBuilderEntry(productPageResponse)
    const footerProps = getFirstBuilderEntry(footerResponse)

    return {
      refreshUrl: req?.url,
      page: pageProps || null,
      footer: footerProps || null,
      errorCode,
      product,
      urlPath,
    }
  } catch (err) {
    return {
      errorCode,
      refreshUrl: req?.url,
    }
  }
}

function getFirstBuilderEntry(response: { results: Array<unknown> }) {
  return response.results && response.results.length > 0 && response.results[0]
}

export default Error
