import { useEffect, type KeyboardEvent, type RefObject } from 'react'

import { isTabKeyEvent, isShiftKeyEvent } from '@/legacy/core/utils/dom/keyPressed'

import useEventCallback from '../useEventCallback'

import { getFocusableElements } from '../utils'

export const useLockFocusOnElements = (elementRef: RefObject<HTMLElement>, enabled: boolean) => {
  const handleKeyDown = useEventCallback(
    (event) => {
      const isTabKey = isTabKeyEvent(event)

      if (!enabled || !isTabKey || !elementRef?.current) return

      const activeElement = document.activeElement
      const isShiftKey = isShiftKeyEvent(event)
      const { firstFocusableElement, lastFocusableElement } = getFocusableElements(
        elementRef.current,
      )

      if (isShiftKey && activeElement === firstFocusableElement) {
        event.preventDefault()
        lastFocusableElement.focus()
      }
      if (activeElement === lastFocusableElement) {
        event.preventDefault()
        firstFocusableElement.focus()
      }
    },
    [enabled],
  )

  useEffect(() => {
    document.addEventListener<'keydown'>('keydown', (e) =>
      handleKeyDown(e as unknown as KeyboardEvent<Element>),
    )

    return () =>
      document.removeEventListener<'keydown'>('keydown', (e) =>
        handleKeyDown(e as unknown as KeyboardEvent<Element>),
      )
  }, [handleKeyDown])
}
