import type { KeyboardEvent } from 'react'

import { KEYBOARD_KEY_EVENT_PROPS } from './constants'

export function isTabKeyEvent(event: KeyboardEvent) {
  const key = event.key || event.keyCode || event.which

  return key === KEYBOARD_KEY_EVENT_PROPS.tab.key || key === KEYBOARD_KEY_EVENT_PROPS.tab.code
}

export function isEscapeKeyEvent(event: KeyboardEvent) {
  const key = event.key || event.keyCode || event.which

  return key === KEYBOARD_KEY_EVENT_PROPS.escape.key || key === KEYBOARD_KEY_EVENT_PROPS.escape.code
}

export function isShiftKeyEvent(event: KeyboardEvent) {
  return event.shiftKey
}
