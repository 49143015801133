import { Icon } from '@/legacy/components/Icon'
import type { CoreIcon } from '@/legacy/components/Icon/types'

import { TextField, type TextFieldProps } from '../TextField'

import { ButtonStyles } from './styles'

export interface TextFieldWithButtonProps extends TextFieldProps {
  icon?: CoreIcon
  buttonAriaLabel?: string
}

const TextFieldWithButton = ({
  icon,
  buttonAriaLabel,
  inputRef,
  children,
  ...props
}: TextFieldWithButtonProps) => (
  <TextField ref={inputRef} {...props}>
    <ButtonStyles type="submit" modifier="ghost" size="sm" aria-label={buttonAriaLabel}>
      {!!icon && <Icon icon={icon} width={18} height={18} aria-hidden className="iwt__magnifier" />}
      {children}
    </ButtonStyles>
  </TextField>
)
export default TextFieldWithButton
