import { keyframes } from '@emotion/react'
import { toCustomPropertiesString } from 'object-to-css-variables'

/**
 * --colors-surface-snow
 */
const defaultColors = {
  white: '255, 255, 255',
  black: '0, 8, 38',
  snow: 'var(--colors-white)',
  palegray: '245, 247, 249',
  'surface-snow': 'var(--colors-snow)',
  'surface-palegray': 'var(--colors-palegray)',
  'surface-lightgray': '236,236,236',
  'surface-silver': '216,216,216',
  'surface-darkgrey': '87,87,85',
  'happy-blue': '0, 158, 227',
  'dark-low': 'var(--colors-black), 0.44',
  'dark-medium': 'var(--colors-black), 0.66',
  'dark-high': 'var(--colors-black), 0.8',
  'text-dark-low': 'var(--colors-dark-low)',
  'text-dark-medium': 'var(--colors-dark-medium)',
  'text-dark-high': 'var(--colors-dark-high)',
  'text-light-solid': 'var(--colors-white)',
  'text-light-high': 'var(--colors-white), 0.8',
  accent: '230, 56, 136',
  header: '200,24,105',
  accent300: '231, 66, 142',
  accent700: '228, 43, 128',
  primary: 'var(--colors-accent)',
  primary300: 'var(--colors-accent300)',
  primary700: 'var(--colors-accent700)',
  secondary: 'var(--colors-accent)',
  secondary300: 'var(--colors-accent300)',
  secondary700: 'var(--colors-accent700)',
  pink: '216, 43, 128',
  'red-ribbon': '248, 24, 77, 1',
}

/**
 * Medusa :)
 * --theme-surface-snow
 */
const medusaTheme = {
  white: 'var(--colors-white)',
  black: 'var(--colors-black)',
  snow: 'var(--colors-white)',
  palegray: 'var(--colors-palegray)',
  'surface-snow': 'var(--colors-surface-snow)',
  'surface-palegray': 'var(--colors-surface-palegray)',
  'surface-lightgray': 'var(--colors-surface-lightgray)',
  'surface-silver': 'var(--colors-surface-silver)',
  'surface-darkgrey': 'var(--colors-surface-darkgrey)',
  'happy-blue': 'var(--colors-happy-blue)',
  'dark-low': 'var(--colors-dark-low)',
  'dark-medium': 'var(--colors-dark-medium)',
  'dark-high': 'var(--colors-dark-high)',
  'text-dark-low': 'var(--colors-text-dark-low)',
  'text-dark-medium': 'var(--colors-text-dark-medium)',
  'text-dark-high': 'var(--colors-text-dark-high)',
  'text-light-solid': 'var(--colors-text-light-solid)',
  'text-light-high': 'var(--colors-text-light-high)',
  'primary-300': 'var(--colors-primary300)',
  primary: 'var(--colors-primary)',
  'primary-700': 'var(--colors-primary700)',
  'secondary-300': 'var(--colors-secondary300)',
  secondary: 'var(--colors-secondary)',
  'secondary-700': 'var(--colors-secondary700)',
  'accent-300': 'var(--colors-accent300)',
  accent: 'var(--colors-accent)',
  'accent-700': 'var(--colors-accent700)',
  pink: '216, 43, 128',
  'red-ribbon': '248, 24, 77, 1',
}

/**
 * --colors-*
 * --theme-*
 * --space-*
 * ...
 */
const theme = {
  colors: defaultColors,
  theme: {
    ...medusaTheme,
  },
  space: {
    1: ' 0.5rem',
    2: ' 1rem',
    3: ' 1.5rem',
    4: ' 2rem',
    5: ' 2.5rem',
    6: ' 3rem',
    7: ' 3.5rem',
    8: ' 4rem',
    9: ' 4.5rem',
    10: ' 5rem',
    11: ' 5.5rem',
    s: 'var(--space-1)',
    m: 'var(--space-3)',
    l: 'var(--space-5)',
    b: 'var(--space-7)',
  },
  radii: {
    default: '0.625rem',
    sm: '0.75rem',
  },
  shadows: {
    divider: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)',
    default: '0 1px 4px 0 rgba(91, 98, 122, 0.3)',
    lightGray: '0 1px 8px rgba(0, 8, 38, 0.08)',
    primary: '0px 0px 1px 4px rgba(var(--colors-primary), 0.3)',
  },
  zIndices: {},
  transitions: {
    default: '0.3s ease',
  },
  borders: {
    lightGray: '1px solid rgba(0, 8, 38, 0.08)',
  },
}

export type ThemeColors = typeof defaultColors

export const CONFIG = {
  maxWidths: {
    xg: '1360px',
    lg: '1044px',
    md: '960px',
    sm: '650px',
  },
  media: {
    sm: '@media (min-width: 480px)',
    md: '@media (min-width: 600px)',
    lg: '@media (min-width: 960px)',
    xlg: '@media (min-width: 1016px)',
    mobileLg: '@media (max-width: 960px)',
    mobileMd: '@media (max-width: 600px)',
    mobileSm: '@media (max-width: 480px)',
    mobileXs: '@media (max-width: 360px)',
  },
  theme,
} as const

const createThemeVars = (themeColors?: { colors?: Partial<typeof defaultColors> }) => {
  const colors: Partial<typeof defaultColors> = {}
  if (themeColors?.colors) {
    const colorsKey = Object.keys(themeColors.colors) as Array<keyof typeof themeColors.colors>
    colorsKey.forEach((key) => {
      colors[key] = themeColors.colors?.[key] || defaultColors[key]
    })
  }

  return toCustomPropertiesString({ colors })
}

export const themeUtils = {
  createThemeVars,
}

export const COMMON_ANIMATION_ATTRIBUTES = {
  animationDuration: '233ms',
  animationTimingFunction: 'cubic-bezier(0,0,.21,1)',
  animationFillMode: 'forwards',
  webkitOverflowScrolling: 'touch',
  willChange: 'transform',
}

export const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

export const fadeOut = keyframes`
from {
  opacity: 1;
}
to {
  opacity: 0;
}
`

export const slideInLeft = keyframes`
from {
  transform: translateX(-100%);
}
to {
  transform: translateX(0);
}
`

export const slideOutLeft = keyframes`
from {
  transform: translateX(0);
}
to {
  transform: translateX(-100%);
}
`

export const slideDown = keyframes`
from {
  transform: translateY(-100%);
}
to {
  transform: translateY(0);
}
`

export const slideUp = keyframes`
from {
  transform: translateY(0);
}
to {
  transform: translateY(-100%);
}
`

export const skeletonLoading = keyframes`
from {
  background-color: hsl(200, 20%, 80%);
}
to {
  background-color: hsl(200, 20%, 95%);
}
`
