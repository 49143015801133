import { hasDocument, hasWindow } from './utils'

export function capitalize(str: string) {
  if (typeof str !== 'string' && str !== null && str !== false) {
    return ''
  }

  return str.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function removeHyphenAndCapitalize(input: string): string {
  const words = input.split('-')
  const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  return formattedWords.join(' ')
}

export const toKebabCase = (str: string) =>
  (
    (str &&
      removeAccents(str).match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
      )) ||
    []
  )
    .join('-')
    .toLowerCase()

export const removeAccents = (str: string) => {
  const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
  const accentsOut = 'AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz'
  if (typeof str !== 'string' && str !== null && str !== false) {
    return ''
  }

  const newText = str.split('')
  newText.forEach((letter, index) => {
    const i = accents.indexOf(letter)
    if (i !== -1) {
      newText[index] = accentsOut[i]
    }
  })

  return newText.join('')
}

export function escapeSpecialChars(value: string): string {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function unescapeBackslashes(value: string): string {
  return value.replace(/\\/g, '')
}

export function generateRegexFromWordWithAllAccents(word: string): string {
  const accents: { [key: string]: string } = {
    a: 'aáàãâäå',
    e: 'eéèêë',
    i: 'iíìîï',
    o: 'oóòõôö',
    u: 'uúùûü',
    c: 'cç',
  }

  const cleanedWord = removeAccents(word)

  const regexWord = cleanedWord
    .split('')
    .map((char) => {
      const charWithAccents = accents[char.toLowerCase()] || char
      const escapedChar = escapeSpecialChars(charWithAccents)
      return `[${escapedChar}]`
    })
    .join('')

  return regexWord
}

interface GetTruncatedTextProps {
  text: string
  maxLength: number
  suffix?: string
}

export function getTruncatedText({ text, maxLength, suffix = '...' }: GetTruncatedTextProps) {
  if (text.length <= maxLength) return text

  const slicedText = text.slice(0, maxLength - suffix.length)

  return `${slicedText}${suffix}`
}

export function sanitizeHTMLContent(input: string) {
  if (!hasWindow || !hasDocument || typeof DOMParser === 'undefined') return

  const doc = new DOMParser().parseFromString(input, 'text/html')
  const sanitizedString = doc.body.textContent || ''
  return sanitizedString
}
