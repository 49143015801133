import type { Props as InputMaskProps } from 'react-input-mask'

const cpfMask = '999.999.999-99'
const cnpjMask = '99.999.999/9999-99'
const zipcodeMask = '99999-999'
/**
 * @TODO Tratamento/conversão de mascaras legadas
 */
const MASK_MAP = {
  '000.000.000-00': cpfMask,
  '00.000.000/0000-00': cnpjMask,
  '00000-000': zipcodeMask,
  cpf: cpfMask,
  cnpj: cnpjMask,
  cep: zipcodeMask,
}

export const maskMapper = (mask: InputMaskProps['mask']) => {
  if (typeof mask === 'string') {
    return MASK_MAP[mask.toLowerCase() as keyof typeof MASK_MAP] || mask.replace(/(0)/g, '9')
  }

  return mask
}
