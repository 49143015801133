import type { BuilderContent as Content } from '@builder.io/sdk'
import styled from '@emotion/styled'

import { Image } from '@/legacy/components/Image'
import type { ProductKeys } from '@/legacy/components/Products/constants'
import { BodyM, SubHeading } from '@/legacy/components/Typography'
import { sanitize } from '@/legacy/core/utils/security'

export type Product = {
  key: ProductKeys
  href: string
  title: string
  alt: string
  icon: string
  logo: string
  logoWhite: string
}

export interface HeaderProductsProps {
  products?: Content[]
}

const BASE_URL = `${process.env.NEXT_PUBLIC_HOST}/lno/static-webfiles/`
const SERASA_ICON =
  'https://cdn.builder.io/api/v1/image/assets%2Fb212bb18f00a40869a6cd42f77cbeefc%2F27839bf6ec334fbfbef18b8f14f89325'

const PRODUCT_MENUS = {
  credito: {
    title: 'Serasa Crédito',
    href: '/credito/',
    icon: `${BASE_URL}common/products/square/serasa-credito.svg`,
  },
  premium: {
    title: 'Serasa Premium',
    href: '/premium/',
    icon: `${BASE_URL}common/products/square/serasa-premium.svg`,
  },
  'limpa-nome': {
    title: 'Serasa Limpa Nome',
    href: '/limpa-nome-online/',
    icon: SERASA_ICON,
  },
  score: {
    title: 'Serasa Score',
    href: '/score/',
    icon: `${BASE_URL}common/products/square/score.svg`,
  },
  carteira: {
    title: 'Serasa Carteira Digital',
    href: '/carteira-digital/',
    icon: SERASA_ICON,
  },
  cadastroPositivo: {
    title: 'Serasa Cadastro Positivo',
    href: '/cadastro-positivo/',
    icon: SERASA_ICON,
  },
  voceConsulta: {
    title: 'Você Consulta',
    href: '/voceconsulta/',
    icon: SERASA_ICON,
  },
  serasa: {
    title: 'Consultar seu CPF',
    href: '/',
    icon: `${BASE_URL}common/products/square/serasa.svg`,
  },

  'limpa-nome-parceiro': {
    title: 'Seja parceiro',
    href: '/limpa-nome-online/seja-parceiro/',
    icon: `${BASE_URL}common/products/square/serasa.svg`,
  },
}

const MenuProductStyles = styled.li({
  '& .icon': {
    margin: '0 1rem 0 0',
  },
  '& a': {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    cursor: 'pointer',
  },
})

const Title = styled(SubHeading)`
  padding: 2rem 1rem 1rem;
`

const HeaderProducts = ({ products }: HeaderProductsProps) => {
  const menus = (Object.keys(PRODUCT_MENUS) as (keyof typeof PRODUCT_MENUS)[]).map((menu) => ({
    ...PRODUCT_MENUS[menu],
    ...((products?.find((p) => p.data?.key === menu)?.data || {}) as Product),
    title: PRODUCT_MENUS[menu].title,
  }))

  return (
    <>
      <Title>Produtos</Title>
      {menus.map(({ href, icon, title, alt }, index) => (
        <MenuProductStyles key={`menu_product_${index}`}>
          <BodyM href={sanitize(href)} tag="a" dark="medium" role="menuitem">
            <span className="icon">
              <Image source={icon} width={24} height={24} alt={alt} />
            </span>
            {title}
          </BodyM>
        </MenuProductStyles>
      ))}
    </>
  )
}

export default HeaderProducts
