import { useCallback, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import type { MenuProps } from '@/legacy/components/Menu'

import { useDevice } from '@/ui/shared/providers'

import { AppInstallBanner } from '../AppInstallBanner'
import { BarB2B } from '../BarB2B'

import HeaderSidebar, { type HeaderSidebarProps } from './Sidebar/Sidebar'
import HeaderTop, { type HeaderTopProps } from './Top/Top'

export interface HeaderProps
  extends Pick<HeaderSidebarProps, 'signUp' | 'signIn' | 'products'>,
    Pick<HeaderTopProps, 'logo' | 'product'>,
    Pick<MenuProps, 'menus'> {
  hasBarB2B: boolean
  hasAppInstallBar: boolean
}

const Header = ({
  product,
  products,
  menus,
  signIn,
  signUp,
  logo,
  hasBarB2B,
  hasAppInstallBar,
}: HeaderProps) => {
  const device = useDevice()
  const [openSideBar, setOpenSideBar] = useState(false)
  const handleToggleSideBar = useCallback(() => setOpenSideBar((state) => !state), [])
  const elementsRef = useRef<(Element | null)[]>([])

  useUpdateEffect(() => {
    if (elementsRef.current) {
      elementsRef.current = [
        ...document.querySelectorAll('#main-body > *:not(#sidebar):not(#top-header)'),
      ]
    }

    elementsRef.current?.forEach((element) => {
      if (!element) {
        return
      }

      if (openSideBar) {
        element.setAttribute('aria-hidden', `true`)
      } else {
        element.removeAttribute('aria-hidden')
      }
    })
  }, [openSideBar])

  return (
    <>
      {hasAppInstallBar && <AppInstallBanner device={device} />}
      {hasBarB2B && <BarB2B />}
      <HeaderTop
        open={openSideBar}
        product={product}
        signIn={signIn}
        logo={logo}
        onClickMenu={handleToggleSideBar}
      />
      <HeaderSidebar
        open={openSideBar}
        signIn={signIn}
        signUp={signUp}
        menus={menus}
        products={products}
        onClickMenu={handleToggleSideBar}
      />
    </>
  )
}

export default Header
