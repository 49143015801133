import {
  PRODUCTS_URLS,
  PRODUCT_KEYS,
  type ProductKeys,
} from '@/legacy/components/Products/constants'

export const getProductFromUrl = (url: string | undefined): ProductKeys => {
  if (!url) {
    return PRODUCT_KEYS.serasa
  }

  const path = url.split('?').shift() || ''
  const product = path
    .replace(/^\/|\/$/g, '')
    .split('/')
    .shift() as ProductKeys

  return PRODUCTS_URLS[product] || PRODUCT_KEYS.serasa
}
