const height = 32

export const PRODUCT_KEYS = {
  lno: 'limpa-nome',
  score: 'score',
  ecred: 'ecred',
  credito: 'credito',
  carteira: 'carteira-digital',
  premium: 'premium',
  serasa: 'serasa',
} as const

export const PRODUCTS_URLS = {
  ...PRODUCT_KEYS,
  'carteira-digital': PRODUCT_KEYS.carteira,
  'limpa-nome-online': PRODUCT_KEYS.lno,
  'limpa-nome': PRODUCT_KEYS.lno,
}

export const CONFIG_PRODUCTS = {
  [PRODUCT_KEYS.lno]: {
    width: 128,
    height,
    id: PRODUCT_KEYS.lno,
    url: '/limpa-nome-online',
    name: 'Serasa Limpa Nome',
  },
  [PRODUCT_KEYS.ecred]: {
    width: 78,
    height,
    id: PRODUCT_KEYS.ecred,
    url: `/${PRODUCT_KEYS.ecred}`,
    name: 'Serasa Crédito',
  },
  [PRODUCT_KEYS.credito]: {
    width: 91,
    height: 28,
    id: PRODUCT_KEYS.credito,
    url: `/${PRODUCT_KEYS.credito}`,
    name: 'Serasa Crédito',
  },
  [PRODUCT_KEYS.premium]: {
    width: 128,
    height,
    id: PRODUCT_KEYS.premium,
    url: `/${PRODUCT_KEYS.premium}`,
    name: 'Serasa Premium',
  },
  [PRODUCT_KEYS.score]: {
    width: 86,
    height,
    id: PRODUCT_KEYS.score,
    url: `/${PRODUCT_KEYS.score}`,
    name: 'Serasa Score',
  },
  [PRODUCT_KEYS.serasa]: {
    width: 68,
    height,
    id: PRODUCT_KEYS.serasa,
    url: '/',
    name: 'Serasa',
  },
  [PRODUCT_KEYS.carteira]: {
    width: 113,
    height,
    id: PRODUCT_KEYS.carteira,
    url: `/${PRODUCT_KEYS.carteira}`,
    name: 'Serasa Carteira Digital',
  },
} as const

export type ProductUrls = keyof typeof PRODUCTS_URLS
export type ProductKeys = keyof typeof CONFIG_PRODUCTS

const BASE_URL = `${process.env.NEXT_PUBLIC_HOST}/lno/static-webfiles/common/products/`

export const PRODUCTS = [
  {
    icon: `${BASE_URL}serasa-credito.svg`,
    alt: 'Serasa Crédito',
    description: 'Compare e peça empréstimos e cartões de crédito',
    ...CONFIG_PRODUCTS.credito,
    width: 150,
    height: 48,
  },
  {
    icon: `${BASE_URL}score.svg`,
    alt: 'Serasa Score',
    description: 'Consulte grátis seu Serasa Score',
    ...CONFIG_PRODUCTS.score,
    width: 150,
    height: 48,
  },
  {
    icon: `${BASE_URL}limpa-nome.svg`,
    alt: 'Serasa Limpa nome',
    description: 'Negocie dívidas com até 90% de desconto',
    ...CONFIG_PRODUCTS['limpa-nome'],
    width: 198,
    height: 48,
  },
  {
    icon: `${BASE_URL}serasa-premium.svg`,
    alt: 'Serasa Premium',
    description: 'Evite golpes em seu nome',
    ...CONFIG_PRODUCTS.premium,
    width: 165,
    height: 48,
  },
  {
    icon: `${BASE_URL}cadastro-positivo.svg`,
    alt: 'Serasa Cadastro Positivo',
    description: 'Seu histórico financeiro a seu favor',
    url: '/cadastro-positivo/',
    id: 'cadastro-positivo',
    width: 278,
    height: 48,
  },
]
