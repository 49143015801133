import type { PropsWithChildren } from 'react'

import MenuList from './List'
import Submenu, { type SubMenuProps } from './SubMenu'

export interface MenuProps {
  menus: SubMenuProps[]
  id?: string
  ariaLabel?: string
  role?: string
  tabIndex?: number
  collapse?: boolean
}

const Menu = ({
  id = 'headerMenu',
  role = 'menu',
  ariaLabel = 'Serasa',
  tabIndex = 0,
  collapse = true,
  menus,
  children,
}: PropsWithChildren<MenuProps>) => (
  <div>
    <MenuList role={role} ariaLabel={ariaLabel}>
      {menus.map((itemProps, index) => (
        <Submenu
          {...itemProps}
          key={`mli-${id}-${index}`}
          tabIndex={tabIndex}
          collapse={collapse}
        />
      ))}
      {children}
    </MenuList>
  </div>
)

export default Menu
