import dynamic from 'next/dynamic'

import { LAZY_ICONS_MAPPING, CORE_ICONS_MAPPING } from './icons'
import type { LazyIcon, Icon, CoreIcon } from './types'

export const lazyLoadIconPath = (iconKey: LazyIcon) => {
  const iconPath = LAZY_ICONS_MAPPING[iconKey as LazyIcon]

  return dynamic(() => import('./svg/' + iconPath), {
    loading: () => null,
    ssr: true,
  })
}

export function isLazyIcon(iconKey: Icon): iconKey is LazyIcon {
  return iconKey in LAZY_ICONS_MAPPING
}

export function isCoreIcon(iconKey: Icon): iconKey is CoreIcon {
  return iconKey in CORE_ICONS_MAPPING
}

export const getIconComponent = (iconKey: Icon) => {
  if (isCoreIcon(iconKey)) {
    return CORE_ICONS_MAPPING[iconKey]
  }

  return lazyLoadIconPath(iconKey)
}
