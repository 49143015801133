import { Header } from '@/legacy/components/Header'
import type { BuilderPageModelProduct } from '@/legacy/models'

interface HeaderBlockProps extends BuilderPageModelProduct {}

const HeaderBlock = ({ data }: HeaderBlockProps) => {
  const header = data?.header
  const appInstallBanner = data?.appInstallBanner ?? {}
  const hasAppInstallBar = header?.value?.data?.hasAppInstallBar && !appInstallBanner.hasCookie
  const hasHeader = header && header?.value
  if (!hasHeader) {
    return null
  }

  return <Header {...header.value.data} hasAppInstallBar={hasAppInstallBar} />
}

export default HeaderBlock
