import { useCallback } from 'react'

export const useCallbackWithRefManipulation = () => {
  const callbackWithRefManipulation = useCallback((callback: () => void) => {
    setTimeout(() => {
      callback()
    }, 50)
  }, [])
  return callbackWithRefManipulation
}
