const Icon = () => (
  <>
    <path
      fill="#009f58"
      d="M1024 512c0 282.77-229.23 512-512 512s-512-229.23-512-512c0-282.77 229.23-512 512-512s512 229.23 512 512z"
    ></path>
    <path
      fill="#fff"
      d="M679.297 581.137c-1.895-3.105-6.895-4.969-14.352-8.682-7.503-3.705-44.358-21.709-51.207-24.182-6.864-2.473-11.895-3.713-16.856 3.713-5 7.442-19.367 24.182-23.743 29.151-4.36 4.977-8.721 5.577-16.216 1.857-7.511-3.705-31.655-11.586-60.266-36.878-22.256-19.729-37.294-44.050-41.685-51.484-4.345-7.426-0.431-11.455 3.282-15.161 3.382-3.351 7.503-8.682 11.247-13.004 3.752-4.36 4.992-7.465 7.496-12.434 2.496-4.969 1.256-9.291-0.639-13.004-1.864-3.713-16.856-40.298-23.103-55.197-6.209-14.853-12.465-14.301-16.856-14.301-4.36 0-12.441 1.297-12.441 1.297s-15.022 1.857-21.879 9.291c-6.856 7.434-26.208 25.438-26.208 61.984 0 36.577 26.824 71.945 30.568 76.868 3.752 4.977 51.815 82.484 127.982 112.258 76.174 29.752 76.174 19.822 89.918 18.566 13.713-1.202 44.312-17.965 50.567-35.329 6.248-17.364 6.248-32.255 4.391-35.329zM524.961 256.302c-141.725 0-256.972 114.361-256.972 254.985 0 55.759 18.173 107.444 48.942 149.498l-32.086 94.671 98.731-31.369c40.56 26.616 89.17 42.155 141.386 42.155 141.663 0 256.949-114.369 256.949-254.954 0-140.623-115.286-254.985-256.949-254.985zM830.583 511.287c0 167.463-136.833 303.256-305.621 303.256-53.602 0-103.961-13.682-147.742-37.74l-169.22 53.78 55.159-162.702c-27.833-45.706-43.842-99.308-43.842-156.593 0-167.502 136.833-303.287 305.644-303.287 168.788 0 305.621 135.785 305.621 303.287v0z"
    ></path>
  </>
)
export default Icon
