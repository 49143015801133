import NextImage, { type ImageProps as NextImageProps } from 'next/legacy/image'
import { useState } from 'react'

import { sendError } from '@/legacy/core/error'
import { clearBuilderProps } from '@/legacy/core/utils/dom/clearBuilderProps'
import { sanitize } from '@/legacy/core/utils/security'

import { builderLoader } from './loader'

export type MedusaImageData = {
  url?: string
  alt?: string
  mime_type?: string
  layout?: NextImageProps['layout']
  sizes?: {
    medium: string
    large: string
  }
}

export interface ImageProps extends Omit<NextImageProps, 'src'> {
  source?: NextImageProps['src']
  data?: MedusaImageData
}

const sanitizeUrl = (image: ImageProps['source']) =>
  (typeof image === 'string' ? sanitize(image) : image) || ''

const Image = ({ data, source, alt, width = 0, height = 0, layout, ...otherProps }: ImageProps) => {
  const finalSrc = (data?.url || source) ?? ''
  const sanitizedFinalSrc = sanitizeUrl(finalSrc) as string
  const [hasError, setHasError] = useState(false)

  const onErrorCapture = () => {
    sendError({
      error: new Error('Image component captured an error on load'),
      context: {
        src: sanitizedFinalSrc,
        loadedSrc: builderLoader({
          src: sanitizedFinalSrc,
          width: width as number,
        }),
        userAgent: navigator && navigator.userAgent,
        comments: 'Original image source has been loaded instead.',
      },
    })
    setHasError(true)
  }

  const props: NextImageProps = {
    ...otherProps,
    src: builderLoader({ src: sanitizedFinalSrc, width: width as number, hasError }),
    alt: (alt || data?.alt)?.trim() || '',
    layout: layout || data?.layout,
    width,
    height,
    onErrorCapture,
  }

  if (!props.src) {
    return null
  }

  if (
    data &&
    data.sizes &&
    data.sizes.medium &&
    data.sizes.large &&
    data.mime_type?.includes('svg') === false
  ) {
    props.sizes = `${data.sizes.medium} 1x, ${data.sizes.large} 2x`
  }

  clearBuilderProps(props, ['children'])

  return <NextImage {...props} aria-hidden={props['aria-hidden'] || !props.alt} />
}

export default Image
