const Icon = () => (
  <path
    xmlns="http://www.w3.org/2000/svg"
    fill="#fff"
    stroke="#d4dae0"
    strokeLinejoin="miter"
    strokeLinecap="square"
    strokeMiterlimit="4"
    strokeWidth="20.48"
    d="M509.829 999.199c-270.254 0-489.349-219.116-489.349-489.37s219.095-489.349 489.349-489.349c270.275 0 489.39 219.095 489.39 489.349 0 270.275-219.095 489.37-489.37 489.37zM671.15 482.427l-235.5-139.162c-6.124-3.973-13.517-5.53-20.726-4.342-7.414 1.024-14.254 4.588-19.395 10.035-5.345 5.591-8.028 12.82-8.028 21.75v278.282c0 8.028 2.56 14.95 7.68 20.746s11.489 9.359 19.087 10.711c7.578 1.331 14.705 0 21.402-4.035l235.479-139.162c7.168-3.994 11.94-9.564 14.377-16.712 2.458-7.127 2.458-14.275 0-21.402s-7.229-12.698-14.377-16.712v0z"
  />
)

export default Icon
