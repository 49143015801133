import Script from 'next/script'

import type { SchemasProps } from '@/legacy/models'

export function Schemas({ schemas }: SchemasProps) {
  return (
    <>
      {schemas &&
        schemas.map(({ schema }, index) => (
          <Script
            id={`ld-${index}`}
            key={`scld-${index}`}
            type="application/ld+json">
              {schema}
            </Script>
        ))}
    </>
  )
}
