import { datadogLogs } from '@datadog/browser-logs'

import { getPathname } from '@/legacy/core/utils/dom/utils'
import { datadogRum, type RumInitConfiguration } from '@/libs/dd-browser-rum'

import {
  ALLOWED_TRACING_ORIGINS,
  APP_VERSION,
  COMMON_CONFIG,
  DD_APPLICATION_ID,
  DD_CLIENT_TOKEN,
  DD_ENV,
  DD_IS_SESSION_REPLAY_ENABLED,
  DEFAULT_SAMPLE_RATES,
  HOME_PAGE_ROLLOUT,
  MEDIA_MEDIUM,
  MEDIA_SOURCE,
  SAMPLE_RATES,
  USER_ORIGIN_COOKIE,
} from '../constants'

import type { SampleRate } from './types'

export const serviceUtils = {
  isConfigurationValid,
  setupInitialCookie,
  sendUserOrigin,
  initializeServices,
  stopSessionRecording,
}

function setupInitialCookie() {
  const rumCookieMatch = /\b_dd_s=([^;]+)/.exec(document.cookie)
  datadogRum.setGlobalContextProperty(
    'initial_cookie',
    rumCookieMatch ? rumCookieMatch[1] : 'empty',
  )
}

function sendUserOrigin() {
  const mediaSource = USER_ORIGIN_COOKIE && getDecodedCookie(USER_ORIGIN_COOKIE).get(MEDIA_SOURCE)
  const mediaMedium = USER_ORIGIN_COOKIE
    ? getDecodedCookie(USER_ORIGIN_COOKIE).get(MEDIA_MEDIUM)
    : 'other'

  const userContext = {
    mediaSource,
    mediaMedium,
  }

  datadogRum.setUser(userContext)
  datadogLogs.addLoggerGlobalContext('usr', userContext)
}

const isHomePage = getPathname() === HOME_PAGE_ROLLOUT.path
const isSessionReplayEnabled = DD_IS_SESSION_REPLAY_ENABLED === 'true'

export const shouldStartSessionReplay =
  (isHomePage && HOME_PAGE_ROLLOUT.isSessionReplayEnabled) || isSessionReplayEnabled

let isServiceInitialized = false

function initializeServices(sampleRatesFromPublicEnv?: SampleRate) {
  if (isServiceInitialized) return

  initRum(sampleRatesFromPublicEnv)
  initLogs(sampleRatesFromPublicEnv?.logs)

  if (shouldStartSessionReplay) initSessionReplay()

  isServiceInitialized = true
}

function initRum(sampleRatesFromPublicEnv: SampleRate = DEFAULT_SAMPLE_RATES) {
  const sessionReplaySampleRate = getRecorderSampleRate(sampleRatesFromPublicEnv)
  const rumSampleRate = isHomePage ? sampleRatesFromPublicEnv.rum : SAMPLE_RATES.rum

  const rumConfigs = {
    applicationId: DD_APPLICATION_ID,
    defaultPrivacyLevel: 'mask-user-input',
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ['clickmap'],
    sessionSampleRate: rumSampleRate || 0,
    sessionReplaySampleRate: sessionReplaySampleRate,
    allowedTracingUrls: ALLOWED_TRACING_ORIGINS,
  } as RumInitConfiguration

  datadogRum.init({ ...COMMON_CONFIG, ...rumConfigs })
}

function initSessionReplay() {
  datadogRum.startSessionReplayRecording()
}

function stopSessionRecording() {
  datadogRum.stopSessionReplayRecording()
}

function initLogs(sampleRate?: number) {
  datadogLogs.init({
    ...COMMON_CONFIG,
    sessionSampleRate: sampleRate || SAMPLE_RATES.logs,
    forwardErrorsToLogs: true,
    forwardReports: 'all',
  })
}

const isProduction = DD_ENV === 'prd'
const getDecodedCookie = (cookie: string) => new URLSearchParams(decodeURIComponent(cookie))

function isConfigurationValid(): boolean | never {
  if (isProduction) return validateAppVersion() && validateRequiredEnvironments()
  return validateRequiredEnvironments()
}

function validateAppVersion() {
  const hasAppVersion = APP_VERSION?.length
  if (hasAppVersion) return true
  console.error('[DD_CONFIGURATION_ERROR] Invalid app version.')
  return false
}

function validateRequiredEnvironments() {
  const hasMinimumRequiredEnvironments = Boolean(DD_ENV && DD_APPLICATION_ID && DD_CLIENT_TOKEN)
  if (hasMinimumRequiredEnvironments) return true
  console.error('[DD_CONFIGURATION_ERROR] Missing required envs.')
  return false
}

const getRecorderSampleRate = (sampleRatesFromPublicEnv: SampleRate): number => {
  const { sessionReplay = 0 } = sampleRatesFromPublicEnv

  if (!isHomePage && DD_IS_SESSION_REPLAY_ENABLED === 'true') return SAMPLE_RATES.sessionReplay
  if (isHomePage && HOME_PAGE_ROLLOUT.isSessionReplayEnabled === 'true') return sessionReplay

  return 0
}
