import type { GAEvents, GenerateEventDataLayerProps } from '../models'

import { sanitizeObject, sanitizeText } from './sanitize'

export const EVENT_TYPE = {
  selectContent: 'select_content',
  viewContent: 'view_content',
  success: 'sucesso',
} as const

export const CONTENT_TYPE = {
  appStore: 'app-store',
  googlePlay: 'google-play',
  button: 'button',
  tab: 'tab',
  card: 'card',
  field: 'campo',
  icon: 'icon',
  menu: 'menu',
  link: 'link',
  selo: 'selo',
  video: 'video',
  viewVideo: 'view-video',
  success: 'sucesso',
  banner: 'banner',
  logo: 'logo',
  viewBanner: 'view-banner',
  viewCard: 'view-card',
  closeAppInstall: 'close-app-install',
  openAppInstall: 'open-app-install',
} as const

const GA360_CONTENT_TYPE = {
  [CONTENT_TYPE.button]: 'clique-botao',
  [CONTENT_TYPE.tab]: 'clique-aba',
  [CONTENT_TYPE.card]: 'clique-card',
  [CONTENT_TYPE.logo]: 'clique-logo',
  [CONTENT_TYPE.icon]: 'clique-icone',
  [CONTENT_TYPE.menu]: 'clique-menu',
  [CONTENT_TYPE.video]: 'clique-video',
  [CONTENT_TYPE.link]: 'clique-link',
  [CONTENT_TYPE.field]: 'preencheu-campo',
  [CONTENT_TYPE.viewVideo]: 'visualizacao-video',
  [CONTENT_TYPE.success]: 'sucesso',
  [CONTENT_TYPE.banner]: 'clique-banner',
  [CONTENT_TYPE.viewBanner]: 'visualizacao-banner',
  [CONTENT_TYPE.appStore]: 'clique-botao-baixe-o-app-e-participe-app-store',
  [CONTENT_TYPE.googlePlay]: 'clique-botao-baixe-o-app-e-participe-google-play',
  [CONTENT_TYPE.viewCard]: 'impressao-card',
  [CONTENT_TYPE.closeAppInstall]: 'click-botao-fechar',
  [CONTENT_TYPE.openAppInstall]: 'click-botao-ver',
  [CONTENT_TYPE.selo]: 'clique-selo',
} as const

const GA4_CONTENT_TYPE = {
  [CONTENT_TYPE.button]: 'botao',
  [CONTENT_TYPE.tab]: 'aba',
  [CONTENT_TYPE.card]: 'card',
  [CONTENT_TYPE.logo]: 'logo',
  [CONTENT_TYPE.icon]: 'icone',
  [CONTENT_TYPE.menu]: 'menu',
  [CONTENT_TYPE.video]: 'video',
  [CONTENT_TYPE.field]: 'campo',
  [CONTENT_TYPE.viewVideo]: 'video',
  [CONTENT_TYPE.link]: 'link',
  [CONTENT_TYPE.selo]: 'selo',
  [CONTENT_TYPE.success]: 'sucesso',
  [CONTENT_TYPE.banner]: 'banner',
  [CONTENT_TYPE.viewBanner]: 'banner',
  [CONTENT_TYPE.appStore]: 'botao-baixe-o-app-e-participe-app-store',
  [CONTENT_TYPE.googlePlay]: 'botao-baixe-o-app-e-participe-google-play',
  [CONTENT_TYPE.viewCard]: 'card',
  [CONTENT_TYPE.closeAppInstall]: 'botao-fechar',
  [CONTENT_TYPE.openAppInstall]: 'botao-ver',
} as const

export const dataLayerBaseContentGenerator = ({
  event,
  contentType,
  label,
  area,
  verticalProduct,
  ambiente,
  customProps = {},
}: GenerateEventDataLayerProps): GAEvents => {
  const clearLabel = sanitizeText(label)
  const sanitizedCustomProps = sanitizeObject(customProps)

  const customAction = sanitizedCustomProps?.action as string

  delete sanitizedCustomProps?.content_type
  delete sanitizedCustomProps?.action

  const defaultProps = {
    vertical_produto: verticalProduct,
    area,
    ambiente,
  }

  return {
    ga360: {
      ...defaultProps,
      event,
      categoria: `${verticalProduct}-${area}`,
      acao: customAction || GA360_CONTENT_TYPE[contentType],
      rotulo: clearLabel,
      ...sanitizedCustomProps,
    },
    ga4: {
      ...defaultProps,
      event: `${event}_ga4`,
      item_text: clearLabel,
      ...sanitizedCustomProps,
    },
  }
}

export const dataLayerContentGenerator = ({
  id,
  itemId,
  verticalProduct,
  contentType,
  position,
  customProps,

  ...props
}: GenerateEventDataLayerProps): GAEvents => {
  const baseEventProps = {
    ...dataLayerBaseContentGenerator({
      id,
      contentType,
      position,
      verticalProduct,
      customProps,
      ...props,
    }),
  }
  const contentProps = {
    ...baseEventProps,
    ga4: {
      ...baseEventProps.ga4,
      item_id:
        itemId || `${verticalProduct}_web_${GA4_CONTENT_TYPE[contentType]}_${position}_${id}`,
      content_type:
        sanitizeText(customProps?.content_type as string) || GA4_CONTENT_TYPE[contentType],
    },
  }

  return contentProps
}

export const dataLayerViewContentGenerator = ({
  ...props
}: GenerateEventDataLayerProps): GAEvents => {
  const baseEventProps = {
    ...dataLayerBaseContentGenerator({
      ...props,
    }),
  }
  const contentProps = {
    ...baseEventProps,
    ga4: {
      ...baseEventProps.ga4,
    },
  }

  return contentProps
}

export const getArea = ({
  verticalProduct,
  url,
  shouldRemoveSecondPartOfPath = true,
}: Record<'verticalProduct' | 'url', string | undefined> & {
  shouldRemoveSecondPartOfPath?: boolean
}) => {
  if (!verticalProduct || !url) {
    return ''
  }

  // blog pv
  if (url.includes('/pv/blog')) {
    return 'blog'
  }

  const path = url.replace(/^\/|\/$/g, '').split('/')
  // Verifica se o primeiro nivel é página de produto
  const isProduct =
    verticalProduct.includes(path[0]) ||
    path[0] === verticalProduct ||
    path[0].includes(verticalProduct)

  // Product Home
  if (path.length == 1 && isProduct) {
    return 'home'
  }

  // Remove o verticalProduct da URL
  if (path.length > 1 && isProduct) {
    path.shift()
  }

  if (path.length == 2 && shouldRemoveSecondPartOfPath) {
    path.pop()
  }

  // Remove o Slug da URL
  if (path.length > 2) {
    path.pop()
  }

  return path.join('-')
}
