import cx from 'classnames'

import { useCallback } from 'react'

import { useSelectLinkEvent } from '@/legacy/core/analytics/hooks/useSelectLinkEvent'

import { Typography } from '../Typography'

import type { FooterModel } from './Footer.model'

type FooterSitemapProps = Pick<FooterModel, 'column'>

export const FooterSitemap = ({ column }: FooterSitemapProps) => {
  const selectLinkEvent = useSelectLinkEvent()

  const handleSelectSitemapLink = useCallback(
    (text: string) => {
      selectLinkEvent({
        label: `${text}`,
        id: '01',
      })
    },
    [selectLinkEvent],
  )

  return (
    <div className="footer-columns">
      {column.map((column, colIndex) => (
        <div className="footer-column" key={`foc_t${colIndex}`}>
          <Typography className={cx('footer-link', 'footer-title')} modifier="heading-xs" tag="p">
            {column.title}
          </Typography>
          <ul>
            {column.links.map((link, linkIndex) => (
              <Typography
                key={`fol_${colIndex}_link_${linkIndex}`}
                modifier="body-p"
                tag="li"
                className="footer-link"
              >
                {link.url ? (
                  <a
                    href={link.url}
                    onClick={() => {
                      handleSelectSitemapLink(link.text)
                    }}
                  >
                    {link.text}
                  </a>
                ) : (
                  link.text
                )}
              </Typography>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
