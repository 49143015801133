import type { ContentReview, SchemasProps } from '@/legacy/models'

type ReviewSchemaProps = { pageTitle: string; contentReview?: ContentReview }

const createContentReviewSchema = ({ pageTitle, contentReview }: ReviewSchemaProps) => {
  if (!pageTitle || !contentReview?.averageRating) {
    return null
  }

  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'CreativeWorkSeries',
    name: pageTitle,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: contentReview.averageRating,
      bestRating: '5',
      ratingCount: contentReview.ratingCount,
    },
  }
  return { schema: JSON.stringify(schema) }
}

type GenerateSchemasProps = {
  defaultSchemas?: SchemasProps['schemas']
  pageTitle: string
  contentReview?: ContentReview
}

export const generateSchemas = ({
  pageTitle,
  contentReview,
  defaultSchemas,
}: GenerateSchemasProps) => {
  const schemas = []

  const contentReviewSchema = createContentReviewSchema({ pageTitle, contentReview })
  if (contentReviewSchema) {
    schemas.push(contentReviewSchema)
  }
  if (defaultSchemas) {
    schemas.push(...defaultSchemas)
  }

  return schemas
}
