import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'

import { Button } from '@/legacy/components/Button'

import { CONFIG } from '@/ui/shared/styles'

import { IconClose } from './IconClose'

interface SidebarButtonCloseProps {
  open: boolean
  onClick: () => void
}

const SidebarButtonCloseStyles = styled(Button)({
  width: '100%',
  justifyContent: 'start',
  height: '3rem',

  [CONFIG.media.lg]: {
    height: '4rem',
  },
})

const SidebarButtonClose = ({ open, onClick }: SidebarButtonCloseProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        buttonRef.current?.focus()
      }, 300)
    }
  }, [open])

  return (
    <SidebarButtonCloseStyles
      ref={buttonRef}
      className="headerClose"
      modifier="solid-invert"
      aria-label="Fechar Menu"
      onClick={onClick}
    >
      <IconClose aria-hidden />
      <span>Fechar</span>
    </SidebarButtonCloseStyles>
  )
}

export default SidebarButtonClose
