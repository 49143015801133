import styled from '@emotion/styled'

import { useCallback, useState } from 'react'

import { useUpdateEffect } from 'react-use'

import { SearchBar } from '@/legacy/components/SearchBar'
import type { SearchBarProps } from '@/legacy/components/SearchBar/SearchBar'
import { useEscKey } from '@/legacy/hooks/useEscKey'
import { useIsMobile } from '@/legacy/hooks/useIsMobile'
import { useLockFocusOnElements } from '@/legacy/hooks/useLockFocusOnElements'
import {
  COMMON_ANIMATION_ATTRIBUTES,
  fadeIn,
  fadeOut,
  slideDown,
  slideUp,
} from '@/ui/shared/styles'

interface SearchSectionStylesProps {
  open: boolean
}

const SearchSectionOverlay = styled.div<SearchSectionStylesProps>(({ open }) => ({
  position: 'absolute',
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  cursor: 'pointer',

  ...COMMON_ANIMATION_ATTRIBUTES,
  animationName: `${fadeIn}`,

  ...(!open && {
    animationName: `${fadeOut}`,
  }),
}))

const SearchSectionWrapper = styled.div<SearchSectionStylesProps>(({ open }) => ({
  position: 'fixed',
  left: '0',
  top: '64px',
  width: '100%',
  backgroundColor: 'white',
  padding: '3.5rem auto',
  zIndex: '-1',

  '& :first-of-type > div': {
    margin: '0 1rem',
  },

  ...COMMON_ANIMATION_ATTRIBUTES,
  animationName: `${slideDown}`,

  ...(!open && {
    animationName: `${slideUp}`,
  }),
}))

type SearchSectionProps = SearchBarProps & {
  open: boolean
  onClose: () => void
}

type SearchBarStateTypes = 'close' | 'open' | 'animating'

const SearchSection = ({ open, onClose, ...props }: SearchSectionProps) => {
  const [animationState, setAnimationState] = useState<SearchBarStateTypes>('close')
  const { ref } = useEscKey(onClose)
  const isMobile = useIsMobile()
  const placeholderText = isMobile
    ? 'O que você está buscando?'
    : 'O que você está buscando? Digite aqui!'

  useLockFocusOnElements(ref, open)

  useUpdateEffect(() => {
    setAnimationState('animating')
  }, [open])

  const toggleAnimationState = useCallback(() => {
    setAnimationState(open ? 'open' : 'close')
  }, [open])

  return (
    <SearchSectionWrapper
      ref={ref}
      hidden={animationState === 'close'}
      onAnimationEnd={toggleAnimationState}
      open={open}
    >
      <SearchBar
        required
        customFocus
        autoSelect
        centerPlaceholder
        className="ecs-container"
        icon="magnifier"
        colorModifier="gray"
        placeholder={placeholderText}
        onClose={onClose}
        forceInputFocus={open}
        {...props}
      />
      <SearchSectionOverlay
        onClick={onClose}
        onKeyPress={open ? onClose : undefined}
        aria-label="Fechar campo de pesquisa do blog"
        role="button"
        tabIndex={0}
        open={open}
      />
    </SearchSectionWrapper>
  )
}

export default SearchSection
