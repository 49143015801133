import { useEffect, useRef, type RefObject, type SyntheticEvent } from 'react'

const isESCKey = (event: KeyboardEvent) => {
  const key = event.key || event.keyCode
  return key === 'Escape' || key === 'Esc' || key === 27
}

export function useEscKey<E extends HTMLElement = HTMLDivElement>(
  callback: (event?: SyntheticEvent<Element, Event>) => void,
  ref?: RefObject<E>,
) {
  const newRef = useRef<E>(ref?.current || null)

  useEffect(() => {
    const elRef = newRef.current

    const handleEscape = (event: KeyboardEvent) => {
      if (!isESCKey(event)) {
        return
      }

      callback()
    }

    elRef?.addEventListener('keyup', handleEscape)
    return () => {
      elRef?.removeEventListener('keyup', handleEscape)
    }
  }, [callback, ref])

  return { ref: newRef }
}
