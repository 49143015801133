import { useCallback, useMemo, useState, type ChangeEvent } from 'react'

type InputState = Partial<ValidityState> & Record<'initial' | 'typed', boolean>

export function useValidation() {
  const [state, setState] = useState<InputState>({
    initial: true,
    typed: true,
  })

  const hasError = useMemo(() => !state.initial && !state.valid, [state.initial, state.valid])
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const validity = event.target.validity || {}

    if (!['blur', 'change'].includes(event?.type)) {
      return
    }

    if (event.type === 'blur') {
      setState((state) => ({
        ...state,
        initial: event?.type === 'blur' && state.typed ? false : state.initial,
      }))

      return
    }

    setState((state) => ({
      ...state,
      typed: event.target.value ? true : state.typed,
      initial: state.typed ? false : state.initial,
      badInput: validity.badInput,
      customError: validity.customError,
      patternMismatch: validity.patternMismatch,
      rangeOverflow: validity.rangeOverflow,
      rangeUnderflow: validity.rangeUnderflow,
      stepMismatch: validity.stepMismatch,
      tooLong: validity.tooLong,
      tooShort: validity.tooShort,
      typeMismatch: validity.typeMismatch,
      valid: validity.valid,
      valueMissing: validity.valueMissing,
    }))
  }, [])

  return {
    state,
    handleChange,
    hasError,
  }
}
