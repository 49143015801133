/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useCallback, useRef, type DependencyList, type KeyboardEvent } from 'react'

export function useEventCallback(fn: (event: KeyboardEvent) => void, dependencies: DependencyList) {
  const ref = useRef<(event: KeyboardEvent) => void>(() => {
    throw new Error('Cannot call an event handler while rendering.')
  })

  useEffect(() => {
    ref.current = fn
  }, [fn, ...dependencies])

  return useCallback(
    (event: KeyboardEvent) => {
      const fn = ref.current
      return fn(event)
    },
    [ref],
  )
}

export default useEventCallback
