import type { GetServerSidePropsContext } from 'next'

import { APP_INSTALL_BANNER_COOKIE_KEY } from '@/legacy/components/AppInstallBanner/constants'

export function getAppInstallBannerCookie(req: GetServerSidePropsContext['req']) {
  const hasCookie = !!req.cookies[APP_INSTALL_BANNER_COOKIE_KEY]

  return { hasCookie }
}

export const getStoreName = (device?: string) => (device === 'android' ? 'Play Store' : 'App Store')

export const getButtonAriaLabel = (store: string) => `Baixar ou abrir aplicativo na ${store}`
