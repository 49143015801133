const FOCUSABLE_ELEMENTS_QUERY = [
  'a[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
].join(',')

const isElementVisible = (element: HTMLElement) =>
  element.offsetParent !== null && element.offsetHeight > 0

export const getFocusableElements = (container: HTMLElement) => {
  const queryResult = container.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS_QUERY)
  const allElements = Array.from(queryResult)
  const elementsVisible = allElements.filter(isElementVisible)
  const firstFocusableElement = elementsVisible[0]
  const lastFocusableElement = elementsVisible[elementsVisible.length - 1]

  return {
    firstFocusableElement,
    lastFocusableElement,
  }
}
