export const CookieStorage = {
  set: (cookieName: string, cookieValue: string, expires?: string, path: string = '/') => {
    if (typeof document === 'undefined') return

    window.document.cookie = `${cookieName}=${cookieValue};${
      expires && `expires=${expires};`
    }path=${path}`
  },
  get: (cookieName: string) => {
    if (typeof document === 'undefined') return

    const cookieMatch = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'))
    if (cookieMatch) return cookieMatch[2]
  },
  delete: (cookieName: string) => {
    if (typeof document === 'undefined' || typeof window === 'undefined') return

    document.cookie = `${cookieName}=null;domain=${getDomain()};expires=${getExpiredDate()};path=/`

    function getDomain() {
      return window.location.host.replace('www.', '')
    }

    function getExpiredDate() {
      const now = new Date()
      now.setTime(now.getTime() + -1 * 24 * 60 * 60e3)
      return now.toUTCString()
    }
  },
}
