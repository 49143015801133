import Script from 'next/script'

import { GtmVCPE } from '@/legacy/components/Scripts/GtmVcpe'
import { getArea } from '@/legacy/core/analytics/utils'
import { sanitizeUrl } from '@/legacy/core/utils/dom/security'
import type { AnalyticsData } from '@/legacy/models'

interface Props extends AnalyticsData {
  title: string
  url: string
  isLoggedIn?: boolean
}

type GtmCodeProps = Pick<Props, 'gtmCode'>

const GtmScriptHead = ({ gtmCode }: GtmCodeProps) => (
  <Script
    id="gtm-imp-head"
    strategy="afterInteractive"
  >
    {`(function(w,d,h,s,l,i){w[l]=w[l]||[];if(h.search.indexOf('gtm=f')>-1){return};w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,location,'script','dataLayer','${gtmCode.code}');
      window.gptadslots=window.gptadslots||{};window.googletag=window.googletag||{cmd:[]};
    `}
  </Script>
)

const GtmScriptBody = ({ gtmCode }: GtmCodeProps) => (
  <noscript
    id="gtm-imp-body"
  >
    <iframe src={sanitizeUrl(`https://www.googletagmanager.com/ns.html?id=${gtmCode.code}`)} height="0" width="0" style={{display:"none",visibility:"hidden"}}></iframe>
  </noscript>
)

const GtmPageView = ({ title, verticalProduct, url, isLoggedIn = false }: Omit<Props, 'gtmCode'> & { url: string }) => {
  const area = getArea({ verticalProduct, url })

  return (
    <>
      {area === "voceconsulta" ? (
        <GtmVCPE title={title} verticalProduct={verticalProduct} area={area} isLoggedIn={isLoggedIn} />
      ) : (
        <Script id="gtm-pv" strategy="afterInteractive">
          {`(function(w,d,l,oa,eet,ed){
            w[d]=w[d]||[];
            var e={'event':'page_view','page_location':l.href,'page_title':'${title}','page_path':l.pathname, 'tipo_projeto': 'web-public-pages-front'};
            w[d].push(oa({},e,ed));
            w[d].push(oa({},e,{'event':e.event+'_ga4'},ed));
            w[d].push(oa({},ed,{'event':eet,'categoria':ed.vertical_produto+'-'+ed.area,'acao':'entrar-tela','rotulo':ed.area}));
            w[d].push(oa({},ed,{'event':eet+'_ga4','item_text':ed.area}));
          })(window,'dataLayer',location,Object.assign,'entrar_tela',{'vertical_produto':'${verticalProduct}','area':'${area}','ambiente': '${isLoggedIn ? 'logado' : 'deslogado'}'});`}
        </Script>
      )}
    </>
  )
}

export const Gtm = ({ gtmCode, ...eventProps }: Props) => {
  const gtm = {
    ...gtmCode,
    code: gtmCode?.code || (process.env.NEXT_PUBLIC_GTM_CODE as string),
  }

  return (
    <>
      <GtmScriptHead gtmCode={gtm} />
      <GtmScriptBody gtmCode={gtm} />
      <GtmPageView {...eventProps} />
    </>
  )
}
